// GetStarted.jsx
import React from "react";
import { motion } from "framer-motion";
import { ArrowRight, CheckCircle, Users, Trophy } from "lucide-react";

const FeatureCard = ({ icon: Icon, title, description }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    viewport={{ once: true }}
    transition={{ duration: 0.6 }}
    className="group relative bg-gradient-to-br from-gray-800 to-gray-900 rounded-3xl shadow-lg p-6 hover:shadow-xl transition-all duration-300"
  >
    <div className="flex items-center justify-center w-12 h-12 bg-gradient-to-r from-gray-600 to-gray-700 text-white rounded-full mb-4">
      <Icon className="w-6 h-6" />
    </div>
    <h4 className="text-xl font-bold text-white mb-2">{title}</h4>
    <p className="text-base text-gray-400 leading-relaxed">{description}</p>
  </motion.div>
);

const GetStarted = () => {
  return (
    <section className="relative py-16 md:py-24 px-4 md:px-8 bg-gradient-to-b from-gray-900 to-black overflow-hidden">
      <div className="max-w-6xl mx-auto">
        {/* Header Section */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <h2 className="text-3xl md:text-4xl font-extrabold text-white mb-6">
            Disfruta de tu evento y déjanos hacer el trabajo pesado
          </h2>
          <p className="text-lg md:text-xl text-gray-400 leading-relaxed">
            Livespeed te lleva a otro nivel al ser más que un simple sistema de
            cronometraje.
          </p>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="mt-8 bg-gradient-to-r from-gray-700 to-gray-800 text-white px-8 py-3 rounded-full font-semibold flex items-center gap-2 mx-auto hover:shadow-lg transition-all duration-300"
          >
            Comienza ahora
            <ArrowRight className="w-5 h-5" />
          </motion.button>
        </motion.div>

        {/* Features Section */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 lg:gap-12 mb-16">
          <FeatureCard
            icon={CheckCircle}
            title="Precisión Absoluta"
            description="Livespeed es un sistema avanzado de cronometraje y una plataforma de registro que ofrece resultados instantáneos."
          />
          <FeatureCard
            icon={Users}
            title="Conexión entre Corredores"
            description="Diseñado para que los participantes se conozcan, compartan logros y formen una comunidad vibrante."
          />
          <FeatureCard
            icon={Trophy}
            title="Logros Personales"
            description="Guarda tus avances, suma victorias y enorgullécete de tu evolución como corredor."
          />
        </div>

        {/* Content Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 lg:gap-12">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
            className="group relative p-6 bg-gradient-to-br from-gray-800 to-gray-900 rounded-3xl shadow-xl hover:shadow-2xl transition-all duration-300"
          >
            <h3 className="text-2xl md:text-3xl font-bold text-white mb-4">
              Simplificamos lo complicado
            </h3>
            <p className="text-base md:text-lg text-gray-400 leading-relaxed mb-6">
              Confía en Livespeed para la organización completa de tu evento.
              Ofrecemos herramientas diseñadas para aliviar la carga de
              planificación y ejecución.
            </p>
            <motion.div
              whileHover={{ scale: 1.02 }}
              className="relative w-full overflow-hidden rounded-2xl shadow-lg pt-[56.25%]"
            >
              <video
                autoPlay
                loop
                muted
                playsInline
                className="absolute inset-0 w-full h-full object-cover transition-transform duration-700 group-hover:scale-110"
              >
                <source
                  src="https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/Screen%20Recording%202024-02-23%20at%200.26.10%20(1).mp4?alt=media&token=6c420160-ecf8-4124-ba35-17909219f471"
                  type="video/mp4"
                />
                Tu navegador no soporta videos.
              </video>
            </motion.div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
            className="group relative p-6 bg-gradient-to-br from-gray-800 to-gray-900 rounded-3xl shadow-xl hover:shadow-2xl transition-all duration-300"
          >
            <h3 className="text-2xl md:text-3xl font-bold text-white mb-4">
              Explora tu alrededor
            </h3>
            <p className="text-base md:text-lg text-gray-400 leading-relaxed mb-6">
              Conecta con otros entusiastas, intercambia equipo y descubre los
              rincones más emocionantes de tu ciudad para practicar tu pasión.
            </p>
            <motion.div
              whileHover={{ scale: 1.02 }}
              className="relative w-full overflow-hidden rounded-2xl shadow-lg pt-[56.25%]"
            >
              <video
                autoPlay
                loop
                muted
                playsInline
                className="absolute inset-0 w-full h-full object-cover transition-transform duration-700 group-hover:scale-110"
              >
                <source
                  src="https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/LiveSpeed.mp4?alt=media&token=6dd53f67-9d9d-4920-8d7f-9c89d0c9d915"
                  type="video/mp4"
                />
                Tu navegador no soporta videos.
              </video>
            </motion.div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default GetStarted;
