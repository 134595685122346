import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db, storage } from "../firebase";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
  uploadBytesResumable,
} from "firebase/storage";

class Users {
  usersCollectionRef = collection(db, "Users");

  async getUserByUid(uid) {
    try {
      const userDocRef = doc(this.usersCollectionRef, uid);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        return { id: userDoc.id, ...userDoc.data() };
      } else {
        console.log("No such user!");
        return null;
      }
    } catch (error) {
      console.error("Error getting user:", error);
    }
  }
}

export const userApi = new Users();

class Events {
  eventsCollectionName = "events";
  categoriesSubCollectionName = "categories";
  participantsSubCollectionName = "participants";
  addonsSubCollectionName = "addons";
  eventsCollectionRef = collection(db, this.eventsCollectionName);

  async getEventsByEventManagerUid(uid) {
    try {
      if (!uid) return;
      const q = query(this.eventsCollectionRef, where("creatorUID", "==", uid));
      const querySnapshot = await getDocs(q);
      const events = [];
      querySnapshot.forEach((doc) => {
        events.push({
          id: doc.id,
          ...doc.data(),
        });
      });

      return events;
    } catch (error) {
      console.error("Error getting events:", error);
      throw error;
    }
  }

  async createEvent(eventData) {
    try {
      // Step 1: Create the event in Firestore without the photo URL
      const eventRef = await addDoc(this.eventsCollectionRef, {
        creatorUID: eventData.creatorUID,
        eventName: eventData.eventName,
        description: eventData.description,
        dateTime: eventData.dateTime,
        price: parseFloat(eventData.price),
        location: eventData.location,
        organizationId: eventData.organizationId || "",
        photo: "",
      });

      let photoUrl = "";

      // Step 2: Upload photo to Firebase Storage if provided
      if (eventData.photo) {
        const storageRef = ref(
          storage,
          `event_images/${eventRef.id}/${eventData.photo.name}`
        );

        await uploadBytesResumable(storageRef, eventData.photo);
        photoUrl = await getDownloadURL(storageRef);

        // Step 3: Update the event with the correct photo URL
        await updateDoc(eventRef, {
          photo: photoUrl,
        });
      }

      // Step 4: Add categories subcollection
      const categoriesCollectionRef = collection(
        eventRef,
        this.categoriesSubCollectionName
      );
      for (const category of eventData.categories) {
        await addDoc(categoriesCollectionRef, {
          name: category.name,
        });
      }

      // Step 5: Add addons subcollection
      const addonsCollectionRef = collection(
        eventRef,
        this.addonsSubCollectionName
      );
      for (const addon of eventData.addons) {
        let addonImageUrl = "";

        // Step 5a: If the addon has an image, upload it to Firebase Storage
        if (addon.image) {
          const addonStorageRef = ref(
            storage,
            `event_images/${eventRef.id}/addons/${addon.id}/${addon.image.name}`
          );
          await uploadBytesResumable(addonStorageRef, addon.image);
          addonImageUrl = await getDownloadURL(addonStorageRef);
        }

        // Step 5b: Add the addon to Firestore, using the image URL if present
        await addDoc(addonsCollectionRef, {
          name: addon.name,
          description: addon.description,
          image: addonImageUrl,
          price: addon.price,
          required: addon.required,
          variants: addon.variants,
        });
      }
    } catch (error) {
      console.log("Error adding event:", error);
    }
  }

  async addParticipantToEvent(eventId, userId, categoryId) {
    try {
      const eventDocRef = doc(db, this.eventsCollectionName, eventId);

      const participantsCollectionRef = collection(eventDocRef, "participants");

      await addDoc(participantsCollectionRef, {
        userId,
        categoryId,
        joinedAt: new Date(), // Fecha de unión, por ejemplo
      });
    } catch (error) {
      console.error("Error al añadir participante al evento:", error);
    }
  }

  async removeParticipantFromEvent(eventId, userId) {
    try {
      const eventDocRef = doc(db, this.eventsCollectionName, eventId);
      const participantsCollectionRef = collection(eventDocRef, "participants");

      const participantQuery = query(
        participantsCollectionRef,
        where("userId", "==", userId)
      );

      const querySnapshot = await getDocs(participantQuery);

      querySnapshot.forEach(async (docSnapshot) => {
        await deleteDoc(docSnapshot.ref);
        console.log(
          `Participante ${userId} eliminado del evento ${eventId} correctamente.`
        );
      });
    } catch (error) {
      console.error("Error al eliminar participante del evento:", error);
    }
  }

  async updateEvent(eventId, updatedData) {
    try {
      const docRef = doc(this.eventsCollectionRef, eventId);

      // 1. Manejo de la imagen del evento
      if (updatedData.photo instanceof File) {
        const storageRef = ref(
          storage,
          `event_images/${eventId}/${updatedData.photo.name}`
        );
        await uploadBytesResumable(storageRef, updatedData.photo);
        const photoUrl = await getDownloadURL(storageRef);
        updatedData.photo = photoUrl; // Actualizar URL de la foto
      }

      // 2. Remover categorías y addons del objeto updatedData antes de actualizar el documento principal
      const { categories, addons, ...eventDataWithoutCategoriesAndAddons } =
        updatedData;

      // Actualizar el documento principal del evento sin incluir categorías ni addons
      await updateDoc(docRef, eventDataWithoutCategoriesAndAddons);

      // 3. Actualizar subcolección de categorías
      const categoriesCollectionRef = collection(
        docRef,
        this.categoriesSubCollectionName
      );

      // Obtener todas las categorías existentes en la base de datos
      const existingCategoriesSnapshot = await getDocs(categoriesCollectionRef);
      const existingCategories = existingCategoriesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Eliminar las categorías que ya no están en updatedData
      const updatedCategoryIds = categories
        .map((category) => category.id)
        .filter(Boolean);
      for (const existingCategory of existingCategories) {
        if (!updatedCategoryIds.includes(existingCategory.id)) {
          const categoryToDeleteRef = doc(
            categoriesCollectionRef,
            existingCategory.id
          );
          await deleteDoc(categoryToDeleteRef); // Eliminar la categoría
        }
      }

      // Actualizar o agregar categorías
      for (const category of categories) {
        if (category.id) {
          const categoryRef = doc(categoriesCollectionRef, category.id); // Obtener referencia de la categoría existente
          await updateDoc(categoryRef, {
            name: category.name,
          });
        } else {
          // Si no tiene id, crear una nueva categoría
          await addDoc(categoriesCollectionRef, {
            name: category.name,
          });
        }
      }

      // 4. Actualizar subcolección de addons
      const addonsCollectionRef = collection(
        docRef,
        this.addonsSubCollectionName
      );

      // Obtener todos los addons existentes en la base de datos
      const existingAddonsSnapshot = await getDocs(addonsCollectionRef);
      const existingAddons = existingAddonsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Eliminar los addons que ya no están en updatedData
      const updatedAddonIds = addons.map((addon) => addon.id).filter(Boolean);
      for (const existingAddon of existingAddons) {
        if (!updatedAddonIds.includes(existingAddon.id)) {
          const addonToDeleteRef = doc(addonsCollectionRef, existingAddon.id);
          await deleteDoc(addonToDeleteRef); // Eliminar el addon
        }
      }

      // Manejo de imágenes de addons y actualización/agregado de addons
      for (const addon of addons) {
        let addonImageUrl = addon.image || "";

        // Si hay una nueva imagen para el addon, subirla
        if (addon.image && addon.image instanceof File) {
          const addonStorageRef = ref(
            storage,
            `event_images/${eventId}/addons/${addon.id || "newAddon"}/${
              addon.image.name
            }`
          );
          await uploadBytesResumable(addonStorageRef, addon.image);
          addonImageUrl = await getDownloadURL(addonStorageRef);
        }

        if (addon.id) {
          const addonRef = doc(addonsCollectionRef, addon.id); // Obtener referencia del addon existente
          await updateDoc(addonRef, {
            name: addon.name,
            description: addon.description,
            image: addonImageUrl, // URL de la imagen o vacío
            price: addon.price,
            required: addon.required,
            variants: addon.variants,
          });
        } else {
          // Si no tiene id, crear un nuevo addon
          await addDoc(addonsCollectionRef, {
            name: addon.name,
            description: addon.description,
            image: addonImageUrl, // URL de la imagen o vacío
            price: addon.price,
            required: addon.required,
            variants: addon.variants,
          });
        }
      }

      console.log("Event updated with subcollections, including deletions!");
    } catch (error) {
      console.error("Error updating event:", error);
    }
  }
  // async updateEvent(eventId, updatedData) {
  //   try {
  //     const docRef = doc(this.eventsCollectionRef, eventId);

  //     // 1. Manejo de la imagen del evento
  //     if (updatedData.photo) {
  //       const storageRef = ref(
  //         storage,
  //         `event_images/${eventId}/${updatedData.photo.name}`
  //       );
  //       await uploadBytesResumable(storageRef, updatedData.photo);
  //       const photoUrl = await getDownloadURL(storageRef);
  //       updatedData.photo = photoUrl; // Actualizar URL de la foto
  //     } else {
  //       delete updatedData.photo; // Eliminar campo si no hay foto actualizada
  //     }

  //     // 2. Remover categorías y addons del objeto updatedData antes de actualizar el documento principal
  //     const { categories, addons, ...eventDataWithoutCategoriesAndAddons } =
  //       updatedData;

  //     // Actualizar el documento principal del evento sin incluir categorías ni addons
  //     await updateDoc(docRef, eventDataWithoutCategoriesAndAddons);

  //     // 3. Actualizar subcolección de categorías
  //     const categoriesCollectionRef = collection(
  //       docRef,
  //       this.categoriesSubCollectionName
  //     );

  //     for (const category of categories) {
  //       if (category.id) {
  //         const categoryRef = doc(categoriesCollectionRef, category.id); // Obtener referencia de la categoría existente
  //         await updateDoc(categoryRef, {
  //           name: category.name,
  //         });
  //       } else {
  //         // Si no tiene id, crear una nueva categoría
  //         await addDoc(categoriesCollectionRef, {
  //           name: category.name,
  //         });
  //       }
  //     }

  //     // 4. Actualizar subcolección de addons
  //     const addonsCollectionRef = collection(
  //       docRef,
  //       this.addonsSubCollectionName
  //     );

  //     for (const addon of addons) {
  //       let addonImageUrl = addon.image || "";

  //       // Si hay una nueva imagen para el addon, subirla
  //       if (addon.image && addon.image instanceof File) {
  //         const addonStorageRef = ref(
  //           storage,
  //           `event_images/${eventId}/addons/${addon.id || "newAddon"}/${
  //             addon.image.name
  //           }`
  //         );
  //         await uploadBytesResumable(addonStorageRef, addon.image);
  //         addonImageUrl = await getDownloadURL(addonStorageRef);
  //       }

  //       if (addon.id) {
  //         const addonRef = doc(addonsCollectionRef, addon.id); // Obtener referencia del addon existente
  //         await updateDoc(addonRef, {
  //           name: addon.name,
  //           description: addon.description,
  //           image: addonImageUrl, // URL de la imagen o vacío
  //           price: addon.price,
  //           required: addon.required,
  //           variants: addon.variants,
  //         });
  //       } else {
  //         // Si no tiene id, crear un nuevo addon
  //         await addDoc(addonsCollectionRef, {
  //           name: addon.name,
  //           description: addon.description,
  //           image: addonImageUrl, // URL de la imagen o vacío
  //           price: addon.price,
  //           required: addon.required,
  //           variants: addon.variants,
  //         });
  //       }
  //     }

  //     console.log("Event updated with subcollections!");
  //   } catch (error) {
  //     console.error("Error updating event:", error);
  //   }
  // }

  async getEventById(
    eventId,
    include = {
      creator: false,
      categories: false,
      participants: false,
      addons: false,
    }
  ) {
    try {
      const docRef = doc(this.eventsCollectionRef, eventId);
      const eventDoc = await getDoc(docRef);

      if (eventDoc.exists()) {
        const eventData = { id: eventDoc.id, ...eventDoc.data() };

        if (include.creator) {
          const creatorUID = eventData.creatorUID;
          if (creatorUID) {
            const creatorData = await userApi.getUserByUid(creatorUID);
            eventData.creator = creatorData;
          }
        }

        if (include.addons) {
          const addonsRef = collection(docRef, this.addonsSubCollectionName);
          const addonsSnapshot = await getDocs(addonsRef);

          const addons = addonsSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          eventData.addons = addons;
        }

        if (include.categories) {
          const categoriesRef = collection(
            docRef,
            this.categoriesSubCollectionName
          );
          const categoriesSnapshot = await getDocs(categoriesRef);

          const categories = categoriesSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          eventData.categories = categories;
        }

        if (include.participants) {
          const participantsRef = collection(
            docRef,
            this.participantsSubCollectionName
          );
          const participantsSnapshot = await getDocs(participantsRef);

          const participantsPromises = participantsSnapshot.docs.map(
            async (doc) => {
              const docData = doc.data();

              const participant = {
                id: doc.id,
                joinedAt: docData.joinedAt,
                categoryId: docData.categoryId,
              };

              if (docData.userId) {
                const userData = await userApi.getUserByUid(docData.userId);
                participant.user = userData;
              }

              return participant;
            }
          );

          // Esperar a que todas las promesas se resuelvan
          const participants = await Promise.all(participantsPromises);

          eventData.participants = participants;
        }

        return eventData;
      } else {
        console.log("No such event!");
        return null;
      }
    } catch (error) {
      console.log("Error getting event:", error);
    }
  }

  async deleteEvent(eventId) {
    try {
      const docRef = doc(this.eventsCollectionRef, eventId);
      const eventDoc = await getDoc(docRef);

      if (eventDoc.exists()) {
        const eventData = eventDoc.data();
        if (eventData.photo) {
          console.log("eventData.photo =>", eventData.photo);
          const photoRef = ref(storage, eventData.photo);
          await deleteObject(photoRef);
        }

        await deleteDoc(docRef);
        console.log("Event deleted!");
      } else {
        console.log("No such event!");
      }
    } catch (error) {
      console.error("Error deleting event:", error);
    }
  }

  async uploadImage(file, filePath) {
    const storageRef = ref(storage, filePath);
    await uploadBytes(storageRef, file); // Upload file to the specified path
    const downloadUrl = await getDownloadURL(storageRef); // Get the download URL
    return downloadUrl;
  }

  async getAllEvents(futureEvents) {
    try {
      let queryRef = this.eventsCollectionRef;

      // Check if only future events are needed
      if (futureEvents) {
        const now = new Date(); // Current date and time
        queryRef = query(
          this.eventsCollectionRef,
          where("dateTime", ">", now.toISOString())
        );
      }

      // Fetch the documents
      const eventsSnapshot = await getDocs(queryRef);
      const eventsData = eventsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        dateTime: doc.data().dateTime ? new Date(doc.data().dateTime) : null,
      }));

      return eventsData;
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  }
}

export const eventsApi = new Events();

class Orders {
  ordersCollectionName = "orders";

  async getOrderById(orderId) {
    try {
      const orderDocRef = doc(db, this.ordersCollectionName, orderId);
      const orderDoc = await getDoc(orderDocRef);

      if (orderDoc.exists()) {
        const orderData = { id: orderDoc.id, ...orderDoc.data() };
        // setOrder(orderData);
        // if (orderData.ItemUID) {
        // await fetchItem(orderData.ItemUID);
        // } else {
        // console.error("ItemUID missing from order data");
        // setError("Datos del ítem no disponibles.");
        // }

        return orderData;
      } else {
        return null;
        // console.log("No se encontró la orden");
        // setError("Orden no encontrada");
      }
    } catch (error) {
      console.log(error);
      console.log(`Order ${orderId} not found`);
    }
  }
}

export const ordersApi = new Orders();

// async createEvent(eventData) {
//   try {
//     let photoUrl = "";

//     // Upload event photo if exists
//     if (eventData.photo) {
//       const storageRef = ref(
//         storage,
//         `event_images/${eventData.id}/${eventData.photo?.name}`
//       );
//       await uploadBytesResumable(storageRef, eventData.photo);
//       const url = await getDownloadURL(storageRef);
//       if (url) photoUrl = url;
//     }

//     // Handle Addon photo uploads
//     const addonsWithPhotos = await Promise.all(
//       eventData.addons.map(async (addon) => {
//         let addonPhotoUrl = "";

//         if (addon.image) {
//           const addonStorageRef = ref(
//             storage,
//             `event_images/${eventData.id}/addons/${addon.id}/${addon.image?.name}`
//           );
//           await uploadBytesResumable(addonStorageRef, addon.image);
//           const addonUrl = await getDownloadURL(addonStorageRef);
//           if (addonUrl) addonPhotoUrl = addonUrl;
//         }

//         // Return the addon with updated photo URL
//         return {
//           ...addon,
//           image: addonPhotoUrl,
//         };
//       })
//     );

//     console.log("photoUrl =>", photoUrl);

//     // Create the final event body
//     const body = {
//       ...eventData,
//       photo: photoUrl,
//       addons: addonsWithPhotos, // Include updated addons with photos
//     };

//     return response;
//   } catch (error) {
//     console.error("Error al crear evento:", error);
//     throw error;
//   }
// }

// async createEvent(eventData) {
//   try {
//     // Step 1: Create the event in Firestore without images
//     const eventRef = await addDoc(this.eventsCollectionRef, {
//       creatorUID: eventData.creatorUID,
//       eventName: eventData.eventName,
//       description: eventData.description,
//       dateTime: eventData.dateTime,
//       price: eventData.price,
//       location: eventData.location,
//       addons: eventData.addons.map((addon) => ({
//         id: addon.id,
//         name: addon.name,
//         price: addon.price,
//         description: addon.description,
//         required: addon.required,
//         variants: addon.variants,
//       })),
//       organizationId: eventData.organizationId,
//       categories: eventData.categories,
//     });

//     const eventId = eventRef.id; // Get the created event ID

//     // Prepare updates for image URLs
//     const updates = {};

//     // Step 2: Upload event photo if provided
//     if (eventData.photo) {
//       const photoUrl = await this.uploadImage(
//         eventData.photo,
//         `event_images/${eventId}/${eventData.photo.name}`
//       );
//       updates.photo = photoUrl;
//     }

//     // Step 3: Upload addon images if provided
//     const updatedAddons = await Promise.all(
//       eventData.addons.map(async (addon) => {
//         if (addon.image) {
//           const addonImageUrl = await this.uploadImage(
//             addon.image,
//             `event_images/${eventId}/addons/${addon.id}/${addon.image.name}`
//           );
//           return { ...addon, image: addonImageUrl }; // Update addon with image URL
//         }
//         return addon;
//       })
//     );

//     updates.addons = updatedAddons;

//     // Step 4: Update the event with the image URLs
//     await updateDoc(doc(db, "events", eventId), updates);

//     console.log("Event created and images uploaded successfully!");
//     return eventId; // Return the event ID or success response if needed
//   } catch (error) {
//     console.error("Error creating event:", error);
//     throw error; // Propagate error for handling
//   }
// }
