import React from "react";
import {
  Calendar,
  Trophy,
  Users,
  Map,
  Trees,
  Users2,
  Bike,
  Hammer,
} from "lucide-react";

const routes = [
  { label: "Eventos", path: "/events", icon: Calendar },
  // { label: "Ranking", path: "/leaderboards", icon: Trophy },
  { label: "Amigos", path: "/friends", icon: Users },
  { label: "Mapa", path: "/livemap", icon: Map },
  { label: "Parques", path: "/parks", icon: Trees },
  { label: "Equipos", path: "/teams", icon: Users2 },
  { label: "Rodar", path: "/rides", icon: Bike },
  // { label: "Workshops", path: "/workshops", icon: Hammer },
];

const SearchPanel = () => {
  return (
    <div className="min-h-screen bg-gray-100 p-6 pt-4 flex items-start justify-center">
      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4 w-full max-w-xl">
        {routes.map((route, index) => {
          const Icon = route.icon;
          return (
            <a
              key={index}
              href={route.path}
              className="group relative overflow-hidden rounded-lg bg-white border border-gray-300 shadow-md transform transition-all duration-300 hover:shadow-lg hover:-translate-y-1"
            >
              {/* Button Background Hover Effect */}
              <div className="absolute inset-0 bg-gradient-to-br from-gray-100 to-transparent opacity-0 group-hover:opacity-10 transition-opacity duration-300" />

              {/* Content Wrapper */}
              <div className="relative p-4 flex flex-col items-center space-y-2">
                {/* Icon Container with Subtle Hover Effect */}
                <div className="p-2 rounded-full bg-white border border-gray-300 group-hover:border-black transition-all duration-300">
                  <Icon className="w-8 h-8 text-gray-800 group-hover:text-black transition-colors duration-300" />
                </div>

                {/* Label */}
                <span className="text-sm font-semibold text-gray-800 group-hover:text-black transition-colors duration-300">
                  {route.label}
                </span>
              </div>

              {/* Subtle Shadow Accent on Hover */}
              <div className="absolute -bottom-10 -right-10 w-20 h-20 bg-gradient-to-br from-gray-300 to-gray-200 rounded-full blur-lg opacity-0 group-hover:opacity-40 transition-all duration-500" />
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default SearchPanel;
