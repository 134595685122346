import React from "react";
import { Button, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const GetInTouch = () => {
  return (
    <Stack
      component="section"
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        py: 10,
        px: 3,
        background: "linear-gradient(135deg, #0d0d0d, #1a1a1a)",
        borderRadius: 4,
        boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.8)",
      }}
    >
      <Typography
        variant="h4"
        component="h2"
        sx={{
          fontWeight: "700",
          textAlign: "center",
          color: "#ffffff",
          textShadow: "0px 4px 10px rgba(255, 255, 255, 0.3)",
          mb: 2,
        }}
      >
        Contáctanos para vivir una nueva experiencia.
      </Typography>
      <Typography
        variant="body1"
        sx={{
          maxWidth: "sm",
          mx: "auto",
          textAlign: "center",
          py: 3,
          color: "#cfcfcf",
          lineHeight: "1.8",
          letterSpacing: "0.5px",
        }}
      >
        Nos comprometemos a ofrecerte una experiencia profesional y
        satisfactoria para mejorar tu rendimiento y disfrutar de tu pasión. Si
        deseas obtener más información sobre nuestros productos y servicios, haz
        clic en el botón de abajo.
      </Typography>
      <Button
        component={Link}
        to="/contact"
        variant="contained"
        size="medium"
        sx={{
          fontSize: "1rem",
          textTransform: "uppercase",
          py: 1.5,
          px: 6,
          mt: 4,
          mb: 2,
          borderRadius: "30px",
          backgroundColor: "#ffffff",
          color: "#0d0d0d",
          fontWeight: "600",
          transition: "all 0.3s ease",
          boxShadow: "0px 4px 15px rgba(255, 255, 255, 0.3)",
          "&:hover": {
            backgroundColor: "#e6e6e6",
            boxShadow: "0px 6px 20px rgba(255, 255, 255, 0.5)",
            transform: "translateY(-3px)",
          },
        }}
      >
        Enviar Mensaje
      </Button>
    </Stack>
  );
};

export default GetInTouch;
