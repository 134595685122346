import React, { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Users, Search } from "lucide-react";

const InitialAvatar = ({ name, photoUrl }) => {
  const getInitials = (fullName) => {
    return fullName
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase())
      .slice(0, 2)
      .join("");
  };

  if (photoUrl) {
    return (
      <img
        src={photoUrl}
        alt={name}
        className="w-12 h-12 rounded-full border-2 border-gray-200 object-cover"
      />
    );
  }

  return (
    <div className="w-12 h-12 rounded-full bg-blue-500 text-white flex items-center justify-center font-bold text-lg">
      {getInitials(name)}
    </div>
  );
};

const ParticipantCard = ({ participant, onClick, isMobile }) => {
  if (isMobile) {
    return (
      <div
        onClick={onClick}
        className="flex items-center p-3 bg-white hover:bg-gray-50 rounded-lg shadow-sm transition-colors cursor-pointer border-b last:border-b-0 border-gray-100"
      >
        <InitialAvatar
          name={participant.user.name}
          photoUrl={participant.user.photoUrl}
        />
        <div className="ml-4 flex-grow overflow-hidden">
          <h2 className="text-sm font-semibold text-gray-800 truncate">
            {participant.user.name}
          </h2>
          <p className="text-xs text-gray-500 truncate">
            @{participant.user.nickName}
          </p>
        </div>
        <div className="text-gray-400 ml-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="m9 18 6-6-6-6" />
          </svg>
        </div>
      </div>
    );
  }

  return (
    <div
      onClick={onClick}
      className="group cursor-pointer transform transition-all duration-200 hover:-translate-y-1"
    >
      <div className="p-4 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow flex flex-col items-center text-center">
        <InitialAvatar
          name={participant.user.name}
          photoUrl={participant.user.photoUrl}
        />
        <div className="mt-3 overflow-hidden">
          <h2 className="text-sm font-semibold text-gray-800 truncate group-hover:text-blue-600 transition-colors">
            {participant.user.name}
          </h2>
          <p className="text-xs text-gray-500 truncate">
            @{participant.user.nickName}
          </p>
        </div>
      </div>
    </div>
  );
};

const CategorySection = ({
  category,
  participants,
  onParticipantClick,
  isMobile,
}) => {
  return (
    <div className="mb-6">
      <h3 className="text-lg font-bold mb-3 text-gray-700">{category}</h3>
      <div
        className={
          isMobile
            ? "space-y-2 divide-y divide-gray-100"
            : "grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4"
        }
      >
        {participants.map((participant, idx) => (
          <ParticipantCard
            key={idx}
            participant={participant}
            onClick={() => onParticipantClick(participant.user.uid)}
            isMobile={isMobile}
          />
        ))}
      </div>
    </div>
  );
};

const EventParticipants = ({ participants, isLoading = false }) => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const isMobile = window.innerWidth <= 768;

  const handleParticipantClick = (uid) => {
    navigate(`/user/${uid}`);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const groupedParticipants = useMemo(() => {
    return participants
      .filter((participant) =>
        participant.user.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .reduce((acc, participant) => {
        const category = participant.category || "General";
        if (!acc[category]) {
          acc[category] = [];
        }
        acc[category].push(participant);
        return acc;
      }, {});
  }, [participants, searchTerm]);

  if (isLoading) {
    return (
      <div className="container mx-auto px-4 py-6 animate-pulse">
        <div className="h-10 bg-gray-200 rounded w-3/4 mb-4"></div>
        <div className="space-y-4">
          {[1, 2, 3].map((section) => (
            <div key={section}>
              <div className="h-6 bg-gray-200 rounded w-1/4 mb-3"></div>
              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
                {[1, 2, 3, 4].map((item) => (
                  <div key={item} className="bg-gray-200 rounded-lg h-40"></div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-6 bg-gray-50">
      <div className="flex flex-col md:flex-row justify-between items-center mb-6 space-y-4 md:space-y-0">
        <h1 className="text-2xl md:text-3xl font-bold text-gray-800">
          Participantes del Evento
        </h1>
        <div className="flex items-center space-x-4 w-full md:w-auto">
          <div className="relative flex-grow">
            <input
              type="text"
              placeholder="Buscar participantes..."
              value={searchTerm}
              onChange={handleSearchChange}
              className="w-full pl-10 pr-4 py-2 rounded-full bg-white border border-gray-200 focus:ring-2 focus:ring-blue-500 focus:outline-none transition-all"
            />
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          </div>
          <div className="relative">
            <div className="absolute -top-2 -right-2 bg-blue-500 text-white text-xs rounded-full w-5 h-5 flex items-center justify-center">
              {participants.length}
            </div>
            <Users className="w-8 h-8 text-gray-600" />
          </div>
        </div>
      </div>

      {Object.entries(groupedParticipants).map(
        ([category, categoryParticipants]) => (
          <CategorySection
            key={category}
            category={category}
            participants={categoryParticipants}
            onParticipantClick={handleParticipantClick}
            isMobile={isMobile}
          />
        )
      )}
    </div>
  );
};

export default EventParticipants;
