import React from "react";
import { ShoppingCart, BarChart2, Users, Lock } from "lucide-react"; // Usando Lucide Icons

const features = [
  {
    title: "Marketplace",
    description:
      "Compra y vende equipo, encuentra ofertas exclusivas y todo lo necesario para tu próxima aventura en ciclismo.",
    icon: <ShoppingCart className="h-12 w-12 text-indigo-500" />,
  },
  {
    title: "Leaderboard",
    description:
      "Compite con otros y escala en el tablero de líderes, mostrando tus logros y tiempos récord.",
    icon: <BarChart2 className="h-12 w-12 text-pink-500" />,
  },
  {
    title: "Comunidades",
    description:
      "Únete a comunidades de ciclistas, participa en eventos y conéctate con otros que comparten tu pasión.",
    icon: <Users className="h-12 w-12 text-green-500" />,
  },
  {
    title: "Control de Acceso",
    description:
      "Gestiona tus entradas y salidas de eventos de manera segura, con tecnología de control de acceso eficiente y confiable.",
    icon: <Lock className="h-12 w-12 text-orange-500" />,
  },
];

const Features = () => {
  return (
    <section className="bg-white py-16 px-4">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-extrabold text-gray-800">
            Descubre Nuestras Funcionalidades
          </h2>
          <p className="mt-4 text-lg text-gray-600">
            Explora las herramientas diseñadas para llevar tu experiencia en el
            ciclismo al siguiente nivel.
          </p>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature, index) => (
            <div
              key={index}
              className="bg-white shadow-lg hover:shadow-xl transition-shadow rounded-xl p-6 text-center flex flex-col items-center"
            >
              <div className="mb-4">{feature.icon}</div>
              <h3 className="text-xl font-semibold text-gray-800">
                {feature.title}
              </h3>
              <p className="text-gray-600 mt-2">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;
