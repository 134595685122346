import * as React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { Home, Trophy, ShoppingCart, User, Search } from "lucide-react";

export default function MobileNavbar() {
  const navigate = useNavigate();
  const location = useLocation();

  const routes = [
    { path: "/home", label: "Home", icon: Home },
    // { path: "/leaderboards", label: "Leaderboards", icon: Trophy },
    { path: "/events?filter=past", label: "Resultados", icon: Trophy },
    { path: "/search", label: "Search", icon: Search },
    { path: "/marketplace", label: "Shop", icon: ShoppingCart },
    { path: "/profile", label: "Perfil", icon: User },
  ];

  const getCurrentValue = () => {
    return routes.findIndex((route) => {
      if (route.path.includes("?")) {
        const routeWithoutQuery = route.path.split("?")[0];
        return routeWithoutQuery;
      } else {
        return route.path === location.pathname;
      }
    });
  };

  return (
    <Box
      sx={{
        width: "100%",
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
        backgroundColor: "white",
        boxShadow: "0px -4px 15px rgba(0, 0, 0, 0.1)",
        borderRadius: "20px 20px 0 0",
        overflow: "hidden",
        borderTop: "1px solid rgba(0, 0, 0, 0.1)",
      }}
    >
      <BottomNavigation
        value={getCurrentValue()}
        onChange={(_, newValue) => navigate(routes[newValue].path)}
        sx={{
          height: 70,
          "& .MuiBottomNavigationAction-root": {
            color: "rgba(0, 0, 0, 0.6)",
            minWidth: "auto",
            padding: "8px 0",
            transition: "color 0.3s ease-in-out",
            "&.Mui-selected": {
              color: "black",
            },
          },
        }}
      >
        {routes.map((route, index) => (
          <BottomNavigationAction
            key={index}
            label={route.label}
            icon={<route.icon size={28} />}
            sx={{
              "&.Mui-selected": {
                "& .MuiBottomNavigationAction-label": {
                  fontSize: "0.8rem",
                  transition: "font-size 0.2s, opacity 0.2s",
                  opacity: 1,
                  fontWeight: "bold",
                  color: "black",
                },
                "& .MuiSvgIcon-root": {
                  color: "black",
                  transition: "transform 0.3s, color 0.3s",
                  transform: "scale(1.1)",
                },
              },
              "& .MuiBottomNavigationAction-label": {
                fontSize: "0.75rem",
                transition: "font-size 0.2s, opacity 0.2s",
                opacity: 0.7,
              },
              "& .MuiSvgIcon-root": {
                transition: "color 0.3s",
              },
              "&:hover .MuiSvgIcon-root": {
                color: "black",
              },
            }}
          />
        ))}
      </BottomNavigation>
    </Box>
  );
}
