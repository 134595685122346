import React from "react";
import { Navigate } from "react-router-dom";
import { useUserAuth } from "../context/userAuthContext";

const ProtectedRoute = ({ children, role }) => {
  const { user } = useUserAuth();

  console.log("user =>", user);

  if (!user) return <Navigate to="/" />;

  if (user.type?.includes("admin")) return children;

  if (role && user.type && !user.type.includes(role))
    return <Navigate to="/notauthorized" />;

  return children;
};

export default ProtectedRoute;
