import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { eventsApi } from "../../lib/client";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

function EventParticipantsInfo() {
  const { eventId } = useParams();
  const [event, setEvent] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchEventData = async () => {
      const eventData = await eventsApi.getEventById(eventId, {
        categories: true,
        participants: true,
      });

      setEvent(eventData);
      setIsLoading(false);
    };

    fetchEventData();
  }, [eventId]);

  if (isLoading) return <div className="container p-4">Loading...</div>;

  return (
    <div className="container p-4">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Category</TableCell>
            <TableCell>Nickname</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {event.participants &&
            event.participants.map((p) => (
              <TableRow
                key={p.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>{p.user.name}</TableCell>
                <TableCell>
                  {event.categories.find((c) => c.id === p.categoryId).name}
                </TableCell>
                <TableCell>{p.user.nickName}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </div>
  );
}

export default EventParticipantsInfo;
