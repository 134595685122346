import React from "react";
import UpcomingEventsBanner from "./Events/UpcomingEventsBanner";
import Stories from "./Stories/Stories";

const Home = () => {
  return (
    <div>
      <Stories />

      <UpcomingEventsBanner />
    </div>
  );
};

export default Home;
