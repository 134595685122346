import React, { useState, useEffect } from "react";
import { useUserAuth } from "../../context/userAuthContext";
import { Link, useNavigate } from "react-router-dom";
import { Avatar, CircularProgress } from "@mui/material";
import {
  User,
  Bike,
  MapPin,
  Calendar,
  Wallet,
  Gift,
  Settings,
  Shield,
  HelpCircle,
  Languages,
  ClipboardCheck,
} from "lucide-react";
import { db } from "../../firebase";
import { doc, onSnapshot } from "firebase/firestore";

const UserMenu = () => {
  const { user, loading } = useUserAuth();
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    if (!loading && !user) {
      navigate("/login");
    } else if (user) {
      const docRef = doc(db, "Users", user.uid);
      const unsubscribe = onSnapshot(docRef, (docSnap) => {
        if (docSnap.exists()) {
          setUserInfo(docSnap.data());
        } else {
          console.log("No such document!");
        }
      });

      return () => unsubscribe();
    }
  }, [user, loading, navigate]);

  const handleNavigation = (path) => {
    navigate(path);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="p-4 space-y-6 bg-white min-h-screen">
      {/* User Profile Section */}
      <div className="flex items-center justify-between border-b pb-4">
        <div className="flex items-center space-x-4">
          <Avatar
            src={userInfo?.photoUrl || "https://via.placeholder.com/40"}
            alt={userInfo?.name || "User Name"}
            className="w-12 h-12"
          />
          <div>
            <h2 className="font-bold text-lg">
              Hola, {userInfo?.name || "User"}
            </h2>
            <Link
              to={`/user/${user?.uid}`}
              className="text-sm text-blue-600 hover:underline"
            >
              Ver perfil
            </Link>
          </div>
        </div>
        <Link to="/addProfile" className="text-gray-600">
          <Settings className="w-6 h-6" />
        </Link>
      </div>

      {/* Main Navigation Buttons */}
      <div className="space-y-2">
        <h3 className="text-lg font-semibold text-gray-700">Mis Opciones</h3>
        <MenuLink to="/addProfile" label="Editar Perfil" icon={User} />
        <MenuLink to="/MyBikes" label="BikeGarage" icon={Bike} />
        {/* <MenuLink to="/MyTags" label="Mis Tags" icon={Tag} /> */}
        <MenuLink to="/MyTeams" label="Mis Equipos" icon={ClipboardCheck} />
        {/* <MenuLink to="/myworkshop" label="Mis WorkShops" icon={Wrench} /> */}
        <MenuLink to="/livelocation" label="Live Location" icon={MapPin} />
        <MenuLink to="/my-events" label="Mis Eventos" icon={Calendar} />
        <MenuLink to="/tickets" label="Mis Tickets" icon={Gift} />
      </div>

      {/* Wallet Section */}
      <div className="space-y-2 mt-6">
        <h3 className="text-lg font-semibold text-gray-700">SpeedCoins</h3>
        <MenuLink to="/wallet" label="Wallet" icon={Wallet} />
        <MenuLink to="/mygiftcards" label="Mis GiftCards" icon={Gift} />
      </div>

      {/* Management Section */}
      <div className="space-y-2 mt-6">
        <h3 className="text-lg font-semibold text-gray-700">Gestión</h3>
        {/* <MenuLink to="/misreceivers" label="Mis Receivers" icon={Trophy} /> */}
        {/* <MenuLink to="/myparks" label="Mis Parques" icon={TreeDeciduous} /> */}
        {userInfo?.type?.includes("eventManager") && (
          <MenuLink to="/miscomites" label="Mis Comités" icon={Calendar} />
        )}
      </div>

      {/* Assistance Section */}
      <div className="space-y-2 mt-6">
        <h3 className="text-lg font-semibold text-gray-700">Asistencia</h3>
        <MenuLink to="/documentacion" label="Documentación" icon={HelpCircle} />
        <MenuLink to="/verificaciones" label="Verificaciones" icon={Shield} />
      </div>

      {/* Language and Currency Section */}
      <div className="flex justify-between mt-6 space-x-2">
        <button className="flex items-center justify-center w-full py-2 border rounded-lg text-gray-700">
          <Languages className="w-5 h-5 mr-2" /> Español (MX)
        </button>
        <button className="flex items-center justify-center w-full py-2 border rounded-lg text-gray-700">
          $ MXN
        </button>
      </div>
    </div>
  );
};

// Reusable component for menu items
const MenuLink = ({ to, label, icon: Icon }) => (
  <Link
    to={to}
    className="flex items-center space-x-4 p-3 bg-gray-100 rounded-lg hover:bg-gray-200 transition"
  >
    <Icon className="w-6 h-6 text-gray-600" />
    <span className="text-gray-800">{label}</span>
  </Link>
);

export default UserMenu;
