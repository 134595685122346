import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { eventsApi, ordersApi } from "../../lib/client";
import SpeedBalanceCheck from "./SpeedBalanceCheck";
import axios from "axios";
import { db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore"; // <-- Import added here
import CaptchaVerification from "./CaptchaVerification";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  useStripe,
  useElements,
  CardElement,
} from "@stripe/react-stripe-js";
import { CreditCard, Coins } from "lucide-react";
import MpCheckout from "../../lib/mp/MpCheckout";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

//const stripePromise = loadStripe(
//  "pk_live_51PTrLbI0WNwaSarcMfRMNedexlsRiIaWvKHO9e3nsvqvwrCiB3yIo4HEyCeKbenkFJkvc8lfWZplW1emuAICnFYb008hJLkY5o"
//);

// const CheckoutForm = ({
//   clientSecret,
//   amount,
//   orderId,
//   navigate,
//   captchaVerified,
//   paymentMethod,
// }) => {
//   const stripe = useStripe();
//   const elements = useElements();
//   const [isProcessing, setIsProcessing] = useState(false);

//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     if (!stripe || !elements || !captchaVerified) {
//       toast.error("Please complete the captcha before proceeding.");
//       return;
//     }

//     const cardElement = elements.getElement(CardElement);

//     setIsProcessing(true);
//     const { error, paymentIntent } = await stripe.confirmCardPayment(
//       clientSecret,
//       {
//         payment_method: {
//           card: cardElement,
//         },
//       }
//     );

//   if (error) {
//     toast.error("Payment error: " + error.message);
//     setIsProcessing(false);
//   } else if (paymentIntent.status === "succeeded") {
//     try {
//       const response = await axios.post(
//         "https://heroic-truck-437503-j4.wl.r.appspot.com/api/payments/create",
//         { orderId, paymentMethod }
//       );

//       if (response.status === 201) {
//         toast.success("Payment successful!");
//         navigate(`/success/${orderId}`);
//       } else {
//         toast.error("Error processing payment confirmation.");
//       }
//     } catch (error) {
//       console.error("Error with payment confirmation:", error);
//       toast.error("Error with payment confirmation.");
//     } finally {
//       setIsProcessing(false);
//     }
//   }
// };

//   return (
//     <form
//       onSubmit={handleSubmit}
//       className="flex flex-col gap-4 p-4 bg-white rounded shadow-md"
//     >
//       <CardElement className="border p-2 rounded bg-white text-black" />
//       <button
//         type="submit"
//         disabled={isProcessing || !stripe || !elements}
//         className={`w-full py-3 rounded font-bold text-black bg-white border border-black flex items-center justify-center gap-2 transition-transform transform active:scale-95 ${
//           isProcessing ? "bg-gray-200" : "hover:bg-gray-100"
//         }`}
//       >
//         {isProcessing ? (
//           "Processing..."
//         ) : (
//           <>
//             <CreditCard className="w-5 h-5" /> Pay ${amount.toFixed(2)}
//           </>
//         )}
//       </button>
//     </form>
//   );
// };

const CheckOut = () => {
  const { uid } = useParams();
  const navigate = useNavigate();
  const [order, setOrder] = useState(null);
  const [event, setEvent] = useState(null);
  const [product, setProduct] = useState(null);
  const [categoryName, setCategoryName] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("stripe");
  const [balance, setBalance] = useState(0);
  const [isPaying, setIsPaying] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const [captchaVerified, setCaptchaVerified] = useState(false);

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const orderData = await ordersApi.getOrderById(uid);
        setOrder(orderData);

        if (orderData.type === "event") {
          await fetchEvent(orderData.itemId, orderData.categoryId);
        } else if (orderData.type === "product") {
          await fetchProduct(orderData.itemId);
        }
      } catch (error) {
        console.error("Error fetching order:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrder();
  }, [uid]);

  // useEffect(() => {
  //   const loadStripePaymentIntent = async () => {
  //     if (selectedPaymentMethod === "stripe" && order) {
  //       try {
  //         const response = await axios.post(
  //           "https://stripe-441018.wl.r.appspot.com/api/create-payment-intent",
  //           {
  //             amount: order.total,
  //             orderId: uid,
  //           }
  //         );
  //         setClientSecret(response.data.clientSecret);
  //       } catch (error) {
  //         toast.error("Error creating Stripe Payment Intent");
  //       }
  //     }
  //   };

  //   loadStripePaymentIntent();
  // }, [selectedPaymentMethod, order, uid]);

  const fetchEvent = async (itemId, categoryId) => {
    try {
      const eventData = await eventsApi.getEventById(itemId, {
        categories: true,
      });
      const categoryNameFound = eventData.categories.find(
        (c) => c.id === categoryId
      );
      setEvent(eventData);
      setCategoryName(categoryNameFound?.name || "Category not found");
    } catch (error) {
      console.error("Error fetching event:", error);
    }
  };

  const fetchProduct = async (itemId) => {
    try {
      const productRef = doc(db, "ShopItems", itemId);
      const productSnap = await getDoc(productRef);
      if (productSnap.exists()) {
        setProduct(productSnap.data());
      } else {
        console.error("Product not found");
      }
    } catch (error) {
      console.error("Error fetching product:", error);
    }
  };

  const handleSupport = () => {
    const eventUrl = window.location.href;
    const whatsappUrl = `https://wa.me/526622995258?text=Hola!%20Necesito%20soporte%20en%20${eventUrl}%20!!!`;
    window.open(whatsappUrl, "_blank");
  };

  const handlePaymentMethodChange = (method) => {
    setSelectedPaymentMethod(method);
    setCaptchaVerified(false);
  };

  const handleBalanceUpdate = (userBalance) => {
    setBalance(userBalance);
  };

  const handleCopyOrderId = () => {
    navigator.clipboard.writeText(uid);
    toast.success("Order ID copied");
  };

  const handleCaptchaVerified = (status) => {
    setCaptchaVerified(status);
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen bg-white">
        <div className="w-16 h-16 border-4 border-black border-dashed rounded-full animate-spin"></div>
      </div>
    );
  }

  if (!order || (!event && !product)) {
    return (
      <div className="text-center p-4 bg-white text-black">
        <h2 className="text-xl font-semibold">Order not found</h2>
      </div>
    );
  }

  return (
    <div className="max-w-xl mx-auto p-6 bg-white text-black rounded-lg shadow-lg">
      <h1 className="text-3xl font-bold text-center mb-6">
        {order.type === "event"
          ? `Pay for Event: ${event.eventName}`
          : `Pay for Product: ${product?.title}`}
      </h1>

      <div className="bg-gray-100 p-4 rounded-lg shadow-md mb-6">
        <div className="flex justify-between items-center mb-4">
          <span className="font-semibold">Order ID:</span>
          <button
            onClick={handleCopyOrderId}
            className="text-gray-600 underline"
          >
            {uid} (Copy)
          </button>
        </div>
        <p>
          <strong>{order.type === "event" ? "Event" : "Product"}:</strong>{" "}
          {order.type === "event" ? event.eventName : product?.title}
        </p>
        {order.type === "event" && (
          <p>
            <strong>Category:</strong> {categoryName || "Category not found"}
          </p>
        )}
        <p>
          <strong>Status:</strong> {order.status || "Not defined"}
        </p>
      </div>

      <div className="bg-gray-100 p-4 rounded-lg shadow-md mb-6">
        <h2 className="text-lg font-semibold mb-2">Resumen de tu orden</h2>
        <ul>
          {order.addons?.map((addon, index) => (
            <li
              key={index}
              className="flex justify-between py-2 border-b border-gray-300"
            >
              <span>{addon.name}</span>
              <span>${addon.price}</span>
            </li>
          ))}
        </ul>
      </div>
      <div className="flex justify-center items-center my-6">
        <button
          onClick={handleSupport}
          className="flex items-center justify-center py-3 px-6 bg-gradient-to-r from-green-500 to-green-600 hover:from-green-600 hover:to-green-700 text-lg font-bold rounded-full text-white shadow-lg hover:shadow-xl gap-2 transition-all duration-300 transform hover:scale-105"
        >
          <WhatsAppIcon fontSize="large" />
          <span> ¿Necesitas ayuda?</span>
        </button>
      </div>

      <div className="bg-gray-100 p-4 rounded-lg shadow-md mb-6">
        <h2 className="text-lg font-semibold mb-2">Método de pago</h2>
        <div className="flex flex-col sm:flex-row gap-4 justify-center">
          <button
            onClick={() => handlePaymentMethodChange("stripe")}
            className={`p-4 border-2 bg-white rounded-xl flex items-center justify-center ${
              selectedPaymentMethod === "stripe"
                ? "border-black"
                : "border-gray-400"
            }`}
          >
            <img
              src="https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/paymentMethods%2Fcredit_card_big.webp?alt=media&token=24fc2a68-6f86-4847-bb13-2f74364947e1"
              alt="Stripe"
              className="w-20"
            />
          </button>
          <button
            onClick={() => handlePaymentMethodChange("speedcoins")}
            className={`p-4 border-2 bg-white rounded-xl flex items-center justify-center ${
              selectedPaymentMethod === "speedcoins"
                ? "border-black"
                : "border-gray-400"
            }`}
          >
            <img
              src="https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/speedy%2FCOINS%20(1).png?alt=media&token=6197a1b6-7594-4a91-8262-d5688fc2bc0a"
              alt="SpeedCoins"
              className="w-20"
            />
          </button>
        </div>
        {selectedPaymentMethod === "speedcoins" ? (
          <SpeedBalanceCheck
            userId={order.userId}
            onBalanceUpdate={handleBalanceUpdate}
            orderId={uid}
            orderTotal={order.total}
            isPaying={isPaying}
            setIsPaying={setIsPaying}
          />
        ) : null}
        {selectedPaymentMethod === "stripe" ? (
          <div className="pt-4">
            <MpCheckout
              amount={order.total}
              orderId={order.id}
              onFinish={() => {
                navigate(`/success/${order.id}`);
              }}
            />
          </div>
        ) : null}
      </div>

      {/* {selectedPaymentMethod === "stripe" &&
        clientSecret &&
        captchaVerified && (
          <div className="bg-gray-100 p-4 rounded-lg shadow-md mb-6">
            <Elements stripe={stripePromise}>
              <CheckoutForm
                clientSecret={clientSecret}
                amount={order.total}
                orderId={uid}
                navigate={navigate}
                captchaVerified={captchaVerified}
                paymentMethod={selectedPaymentMethod === "stripe" ? 2 : 1}
              />
            </Elements>
          </div>
        )} */}
      {/* {selectedPaymentMethod === "stripe" && (
        <CaptchaVerification onVerified={handleCaptchaVerified} />
      )} */}

      <div className="bg-gray-100 p-4 rounded-lg shadow-md text-center">
        <h2 className="text-lg font-semibold">
          Total: ${order.total.toFixed(2)}
        </h2>
      </div>
    </div>
  );
};

export default CheckOut;
