import React, { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  Instagram,
  Facebook,
  Youtube,
  Twitch,
  Twitter,
  Award,
} from "lucide-react";

const LiveStream = () => {
  const [leaderboard, setLeaderboard] = useState([
    { id: 1, name: "Pedro Aguirre", time: "3:01.456", previousPos: 1 },
    { id: 2, name: "Gerardo Garcia", time: "3:02.789", previousPos: 2 },
    { id: 3, name: "Billy Alvarez", time: "3:03.123", previousPos: 3 },
  ]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isVisible, setIsVisible] = useState(false); // Estado para controlar la visibilidad del componente
  const videoRef = useRef(null);
  const componentRef = useRef(null); // Referencia al contenedor principal

  // Video y animaciones solo si el componente es visible
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);

        if (videoRef.current) {
          if (entry.isIntersecting) {
            videoRef.current.play();
          } else {
            videoRef.current.pause();
          }
        }
      },
      { threshold: 0.5 }
    );

    if (componentRef.current) observer.observe(componentRef.current);

    return () => {
      if (componentRef.current) observer.disconnect();
    };
  }, []);

  // Efecto para actualizar el leaderboard si el componente es visible
  useEffect(() => {
    if (!isVisible) return;

    const timer = setTimeout(() => {
      setIsUpdating(true);
      const newLeader = {
        id: 4,
        name: "Angel Gutierrez",
        time: "2:59.789",
        previousPos: 4,
      };

      setTimeout(() => {
        setLeaderboard((prev) => [newLeader, ...prev.slice(0, 2)]);
        setIsUpdating(false);
      }, 1000);
    }, 27000);

    return () => clearTimeout(timer);
  }, [isVisible]);

  return (
    <section
      ref={componentRef}
      className="relative min-h-screen w-full bg-gradient-to-b from-gray-900 to-blue text-white px-4 md:px-10"
    >
      {/* Encabezado y texto promocional */}
      <div className="text-center mb-10 pt-4">
        <h1 className="text-4xl md:text-5xl font-extrabold leading-tight drop-shadow-lg mb-6">
          Servicios de Streaming en tu plataforma preferida!
        </h1>
        {/* Íconos de plataformas */}
        <div className="flex justify-center gap-6 mb-4">
          <Instagram className="h-8 w-8 text-pink-500 hover:scale-110 transition-transform" />
          <Facebook className="h-8 w-8 text-blue-500 hover:scale-110 transition-transform" />
          <Youtube className="h-8 w-8 text-red-500 hover:scale-110 transition-transform" />
          <Twitch className="h-8 w-8 text-purple-500 hover:scale-110 transition-transform" />
          <Twitter className="h-8 w-8 text-blue-400 hover:scale-110 transition-transform" />
        </div>
        <p className="text-lg md:text-xl max-w-3xl mx-auto drop-shadow-md">
          Contamos con tecnología avanzada para streamear resultados en tiempo
          real de tu carrera usando drones, cámaras fijas y más.
        </p>
      </div>

      {/* Contenido principal */}
      <div className="flex flex-col md:flex-row items-start gap-6 w-full max-w-7xl mx-auto">
        {/* Televisión */}
        <div className="relative w-full md:w-2/3 aspect-video bg-black rounded-lg shadow-lg overflow-hidden">
          <video
            ref={videoRef}
            src="https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/chava.mp4?alt=media&token=18c1b43b-03ed-44a4-8287-3f49eb0c2212"
            loop
            muted
            playsInline
            className="absolute inset-0 w-full h-full object-cover"
          />
          {/* Indicador de LIVE */}
          <div className="absolute top-4 right-4 flex items-center">
            <div className="bg-red-500 h-4 w-4 rounded-full animate-pulse"></div>
            <span className="text-white text-sm font-bold ml-2">LIVE</span>
          </div>
        </div>

        {/* Leaderboard Mejorado */}
        <motion.div
          className="w-full md:w-1/3 bg-gray-800/90 backdrop-blur-sm rounded-xl shadow-2xl p-6"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          <div className="flex items-center gap-2 mb-6">
            <Award className="h-6 w-6 text-yellow-500" />
            <h2 className="text-xl font-bold">Leaderboard</h2>
          </div>

          <AnimatePresence mode="popLayout">
            {leaderboard.map((player, index) => (
              <motion.div
                key={player.id}
                initial={
                  index === 0 && isUpdating
                    ? { y: 100, opacity: 0 }
                    : { opacity: 1 }
                }
                animate={{ y: 0, opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{
                  type: "spring",
                  stiffness: 500,
                  damping: 30,
                  mass: 1,
                }}
                className={`
                  mb-3 p-4 rounded-lg relative overflow-hidden
                  ${
                    index === 0
                      ? "bg-gradient-to-r from-yellow-500/20 to-yellow-600/20"
                      : "bg-gray-700/50"
                  }
                `}
              >
                <div className="flex justify-between items-center">
                  <div className="flex items-center gap-4">
                    <span
                      className={`
                        text-lg font-bold w-8 h-8 flex items-center justify-center rounded-full
                        ${
                          index === 0
                            ? "bg-yellow-500 text-black"
                            : index === 1
                            ? "bg-gray-400 text-black"
                            : "bg-orange-500 text-black"
                        }
                      `}
                    >
                      {index + 1}
                    </span>
                    <div className="flex flex-col">
                      <span className="font-semibold">{player.name}</span>
                      <span className="text-sm text-gray-400">
                        {player.time}
                      </span>
                    </div>
                  </div>

                  {player.previousPos > index + 1 && (
                    <motion.div
                      initial={{ scale: 0 }}
                      animate={{ scale: 1 }}
                      className="text-green-500 text-sm"
                    >
                      ↑
                    </motion.div>
                  )}
                </div>
              </motion.div>
            ))}
          </AnimatePresence>
        </motion.div>
      </div>
    </section>
  );
};

export default LiveStream;
