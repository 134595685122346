import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../../firebase";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import axios from "axios";
import toast, { ToastBar } from "react-hot-toast";

function RideCrono() {
  const navigate = useNavigate();
  const { rideId } = useParams();
  const [ride, setRide] = useState(null);
  const [isStart, setIsStart] = useState(true);
  const [time, setTime] = useState(new Date());
  const [selectedRider, setSelectedRider] = useState(null);

  useEffect(() => {
    const fetchRide = async () => {
      const rideRef = doc(db, "Rides", rideId);
      const rideSnap = await getDoc(rideRef);
      if (rideSnap.exists()) {
        const data = rideSnap.data();
        const riders = await getRiders(data.riders);
        setRide({ ...data, riders });
      } else {
        console.log("No such document!");
      }
    };

    fetchRide();
  }, [rideId]);

  useEffect(() => {
    // Update the time every second
    const timerId = setInterval(() => {
      setTime(new Date());
    }, 1000);

    // Cleanup the interval when the component is unmounted
    return () => clearInterval(timerId);
  }, []);

  const getRiders = async (ridersIds) => {
    const ridersList = [];
    for (const riderId of ridersIds) {
      const rider = await getUser(riderId);
      if (rider) {
        ridersList.push(rider);
      }
    }
    return ridersList;
  };

  const getUser = async (userId) => {
    const userRef = doc(db, "Users", userId);
    const userSnap = await getDoc(userRef);
    if (userSnap.exists()) {
      const userData = userSnap.data();
      return { ...userData, id: userSnap.id };
    } else {
      return null;
    }
  };

  function getElapsedTime(dateString1, dateString2) {
    const date1 = parseDate(dateString1);
    const date2 = parseDate(dateString2);

    const diffTime = Math.abs(date2.getTime() - date1.getTime());

    const hours = String(
      Math.floor((diffTime / (1000 * 60 * 60)) % 24)
    ).padStart(2, "0");
    const minutes = String(Math.floor((diffTime / (1000 * 60)) % 60)).padStart(
      2,
      "0"
    );
    const seconds = String(Math.floor((diffTime / 1000) % 60)).padStart(2, "0");
    const milliseconds = String(diffTime % 1000).padStart(3, "0");

    return `${hours}:${minutes}:${seconds}.${milliseconds}`;
  }

  function parseDate(dateString) {
    return new Date(dateString);
  }

  const handleTimerRun = async () => {
    const runsCollection = collection(db, "Runs");
    const runQuery = query(
      runsCollection,
      where("ActiveRun", "==", true),
      where("EventID", "==", rideId),
      where("NFCIdentifier", "==", selectedRider),
      where("ParkID", "==", "a1tGkXljSiNf4Z7blsE0"),
      // where("StartTime", "==", runData.StartTime),
      where("TrackID", "==", "vbW1yge3O5uVO1fR3k1B")
    );
    const querySnapshot = await getDocs(runQuery);
    if (isStart) {
      try {
        if (!querySnapshot.empty) {
          toast.success("Carrera activa encontrada, primero debe de finalizar");
        } else {
          const runData = {
            ActiveRun: true,
            EventID: rideId,
            NFCIdentifier: selectedRider,
            ParkID: "a1tGkXljSiNf4Z7blsE0",
            StartTime: time.toISOString(),
            TrackID: "vbW1yge3O5uVO1fR3k1B",
          };
          const docRef = await addDoc(runsCollection, runData); // Add the document
          toast.success("Tiempo guardado correctamente");
          return docRef.id; // Return the ID of the newly created document
        }
      } catch (error) {
        console.error("Error adding document:", error);
        throw new Error("Failed to create document");
      }
    } else {
      if (querySnapshot.empty) {
        toast.error("Error");
        return;
      }
      const runDoc = querySnapshot.docs[0];
      const elapsed = getElapsedTime(runDoc.data().StartTime, time);

      try {
        await updateDoc(runDoc.ref, {
          FinishTime: time.toISOString(),
          ActiveRun: false,
          ElapsedTime: elapsed,
        });

        toast.success("Carrera finalizada correctamente");
      } catch (error) {
        toast.error("Error al finalizar la carrera");
      }
    }
  };

  const handleChange = (event) => {
    setSelectedRider(event.target.value);
  };

  if (!ride) return <div>Cargando...</div>;

  return (
    <div className="container">
      <Button onClick={() => navigate(-1)} variant="outlined" className="my-2">
        Regresar
      </Button>
      <div className="bg-slate-100 rounded-lg p-4 flex flex-col gap-4">
        <div className="flex gap-4">
          <Button
            fullWidth
            variant={isStart ? "contained" : "outlined"}
            onClick={() => setIsStart(true)}
          >
            Inicio
          </Button>
          <Button
            fullWidth
            variant={!isStart ? "contained" : "outlined"}
            onClick={() => setIsStart(false)}
          >
            Fin
          </Button>
        </div>
        <div>
          <p className="text-center text-xl font-bold">
            Marcaje de {isStart ? "inicio" : "fin"} ⏱️
          </p>
        </div>
        <div>
          <p>Seleccionar corredor</p>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Corredor</InputLabel>
            <Select
              value={selectedRider}
              label="Corredor"
              onChange={handleChange}
            >
              {ride.riders.map((r) => (
                <MenuItem value={r.id}>{r.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="flex justify-center flex-col">
          <div className="flex flex-col py-2">
            <p className="text-center">
              Tiempo actual: {time.toLocaleDateString()}{" "}
              {time.toLocaleTimeString()}
            </p>
            <p className="text-center">Tiempo actual: {time.toISOString()}</p>
          </div>
          <Button
            disabled={selectedRider === null}
            color="success"
            variant="contained"
            onClick={handleTimerRun}
          >
            Tiempo
          </Button>
        </div>
        {/* <div>
          <p className="text-lg font-semibold">{ride.name}</p>
        </div> */}
      </div>
    </div>
  );
}

export default RideCrono;
