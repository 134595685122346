import React, { useState, useEffect, useMemo } from "react";
import { db, auth } from "../../firebase";
import {
  collection,
  onSnapshot,
  addDoc,
  query,
  where,
  deleteDoc,
  doc,
} from "firebase/firestore";
import {
  GoogleMap,
  LoadScript,
  OverlayView,
  KmlLayer,
} from "@react-google-maps/api";
import {
  Menu,
  Map,
  Satellite,
  Mountain,
  Pin,
  Star,
  Flag,
  Trash2,
} from "lucide-react";

const containerStyle = {
  width: "100%",
  height: "100vh",
};

const center = {
  lat: 29.11580571,
  lng: -110.93319296,
};

const getPixelPositionOffset = (width, height) => ({
  x: -(width / 2),
  y: -(height / 2),
});

const LiveMap = () => {
  const [mapType, setMapType] = useState("terrain");
  const [editorMode, setEditorMode] = useState(false);
  const [popup, setPopup] = useState(null);
  const [pins, setPins] = useState([]);
  const [userPins, setUserPins] = useState([]);
  const [newMarker, setNewMarker] = useState({
    title: "",
    icon: "Pin",
    private: false,
  });
  const [userUID, setUserUID] = useState(null);

  useEffect(() => {
    const unsubscribeAuth = auth.onAuthStateChanged((user) => {
      if (user) {
        setUserUID(user.uid);

        const q = query(
          collection(db, "mapMarks"),
          where("creatorUID", "==", user.uid)
        );
        onSnapshot(q, (snapshot) => {
          const userPinsData = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setUserPins(userPinsData);
        });
      } else {
        setUserUID(null);
      }
    });

    return () => unsubscribeAuth();
  }, []);

  useEffect(() => {
    const unsubscribePins = onSnapshot(
      collection(db, "mapMarks"),
      (snapshot) => {
        const pinsData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setPins(pinsData);
      }
    );

    return () => unsubscribePins();
  }, []);

  const handleMapClick = (e) => {
    if (!editorMode) return;

    const { latLng } = e;
    const lat = latLng.lat();
    const lng = latLng.lng();

    setPopup({ lat, lng });
  };

  const handleSaveMarker = async () => {
    if (!userUID) return;

    await addDoc(collection(db, "mapMarks"), {
      latitude: popup.lat,
      longitude: popup.lng,
      creatorUID: userUID,
      icon: newMarker.icon,
      title: newMarker.title,
      private: newMarker.private,
    });

    setPopup(null);
    setNewMarker({ title: "", icon: "Pin", private: false });
  };

  const handleDeletePin = async (pinId) => {
    try {
      await deleteDoc(doc(db, "mapMarks", pinId));
    } catch (error) {
      console.error("Error deleting pin:", error);
    }
  };

  const icons = useMemo(
    () => [
      { name: "Pin", component: <Pin className="w-8 h-8 text-gray-800" /> },
      { name: "Star", component: <Star className="w-8 h-8 text-yellow-500" /> },
      { name: "Flag", component: <Flag className="w-8 h-8 text-red-500" /> },
    ],
    []
  );

  const mapOptions = useMemo(
    () => ({
      mapTypeId: mapType,
      fullscreenControl: false,
      mapTypeControl: false,
      gestureHandling: "greedy",
      styles: [
        {
          featureType: "all",
          stylers: [{ saturation: -80 }, { lightness: 10 }],
        },
      ],
    }),
    [mapType]
  );

  return (
    <LoadScript googleMapsApiKey="AIzaSyBXGQb3-FrLN8POyP-Y4lG_wJNcgFGRd9g">
      <div className="relative w-full h-screen overflow-hidden">
        {/* Botones flotantes */}
        <div className="fixed top-1/2 left-6 transform -translate-y-1/2 z-10 flex flex-col gap-4">
          {/* Botón para habilitar modo Editor */}
          <button
            onClick={() => setEditorMode(!editorMode)}
            className={`w-16 h-16 bg-white p-3 rounded-full shadow-md backdrop-blur-md hover:bg-gray-100 transition flex items-center justify-center ${
              editorMode ? "ring-4 ring-blue-500" : ""
            }`}
          >
            <Pin className="w-8 h-8 text-gray-800" />
          </button>

          {/* Botón para cambiar tipo de mapa */}
          <button
            onClick={() =>
              setMapType((prev) =>
                prev === "terrain"
                  ? "satellite"
                  : prev === "satellite"
                  ? "roadmap"
                  : "terrain"
              )
            }
            className="w-16 h-16 bg-white p-3 rounded-full shadow-md backdrop-blur-md hover:bg-gray-100 transition flex items-center justify-center"
          >
            {mapType === "terrain" && (
              <Mountain className="w-8 h-8 text-gray-800" />
            )}
            {mapType === "satellite" && (
              <Satellite className="w-8 h-8 text-gray-800" />
            )}
            {mapType === "roadmap" && <Map className="w-8 h-8 text-gray-800" />}
          </button>
        </div>

        {editorMode && (
          <div className="fixed top-10 left-6 right-6 bg-white/90 p-4 rounded-xl shadow-lg backdrop-blur-md z-20 max-h-[50vh] overflow-y-auto">
            <h2 className="text-lg font-bold mb-3">Your Pins</h2>
            <div className="grid grid-cols-2 gap-4">
              {userPins.map((pin) => (
                <div
                  key={pin.id}
                  className="bg-gray-100/70 p-3 rounded-lg shadow-sm"
                >
                  <div
                    className="cursor-pointer flex items-center gap-2"
                    onClick={() =>
                      setPopup({ lat: pin.latitude, lng: pin.longitude })
                    }
                  >
                    {icons.find((icon) => icon.name === pin.icon)?.component}
                    <h3 className="text-sm font-bold truncate">{pin.title}</h3>
                  </div>
                  <button
                    onClick={() => handleDeletePin(pin.id)}
                    className="mt-2 w-full bg-red-500 text-white text-sm px-2 py-1 rounded hover:bg-red-700 transition"
                  >
                    Delete
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}

        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={16}
          onClick={handleMapClick}
          options={mapOptions}
        >
          {pins.map((pin) => (
            <OverlayView
              key={pin.id}
              position={{ lat: pin.latitude, lng: pin.longitude }}
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              getPixelPositionOffset={getPixelPositionOffset}
            >
              <div className="text-center scale-125">
                {icons.find((icon) => icon.name === pin.icon)?.component}
                <div className="text-sm bg-white/90 px-3 py-1 rounded-full shadow-md backdrop-blur-md">
                  {pin.title}
                </div>
              </div>
            </OverlayView>
          ))}
          <KmlLayer
            url="https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/Enduro.kml?alt=media&token=cfb193ce-69b7-4739-8165-c5f492020891"
            options={{ preserveViewport: true }}
          />
        </GoogleMap>

        {popup && (
          <div className="fixed inset-0 bg-black/40 flex items-center justify-center z-30 p-4">
            <div className="bg-white rounded-xl p-4 w-full max-w-md shadow-2xl">
              <h3 className="text-lg font-bold mb-3">New Marker</h3>
              <input
                type="text"
                value={newMarker.title}
                onChange={(e) =>
                  setNewMarker((prev) => ({ ...prev, title: e.target.value }))
                }
                placeholder="Title"
                className="border p-2 rounded mb-3 w-full text-sm"
              />
              <div className="mb-3">
                <label className="text-sm font-bold block mb-1">Icon:</label>
                <div className="flex gap-3 justify-center">
                  {icons.map((icon) => (
                    <div
                      key={icon.name}
                      className={`p-3 border rounded-full cursor-pointer ${
                        newMarker.icon === icon.name ? "bg-gray-200" : ""
                      }`}
                      onClick={() =>
                        setNewMarker((prev) => ({ ...prev, icon: icon.name }))
                      }
                    >
                      {icon.component}
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex justify-between gap-3">
                <button
                  onClick={() => setPopup(null)}
                  className="flex-1 bg-gray-200 px-3 py-2 rounded-lg text-sm"
                >
                  Cancel
                </button>
                <button
                  onClick={handleSaveMarker}
                  className="flex-1 bg-blue-500 text-white px-3 py-2 rounded-lg text-sm"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </LoadScript>
  );
};

export default LiveMap;
