import { Box, Button } from "@mui/material";
import { ChevronLeft } from "lucide-react";
import { useNavigate } from "react-router-dom";

function EventStatistics() {
  const navigate = useNavigate();

  return (
    <div className="mt-4">
      <Box sx={{ pb: 2 }}>
        <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
          Regresar
        </Button>
      </Box>
      EventStatistics
    </div>
  );
}

export default EventStatistics;
