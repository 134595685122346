import React, { useState, useEffect } from "react";
import { db } from "../../../firebase";
import {
  collection,
  getDocs,
  doc,
  updateDoc,
  onSnapshot,
} from "firebase/firestore";

const RiderSelect = () => {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [tempUser, setTempUser] = useState(null);

  // Cargar todos los usuarios al montar el componente
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "Users"));
        const usersList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          name: doc.data().name || "Usuario sin nombre",
          photoUrl:
            doc.data().photoUrl ||
            "https://via.placeholder.com/150?text=Sin+Foto", // Manejo de usuarios sin foto
        }));
        setUsers(usersList);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();

    // Escuchar cambios en el documento Livesettings/hermosillo
    const livesettingsDocRef = doc(db, "Livesettings", "hermosillo");
    const unsubscribe = onSnapshot(livesettingsDocRef, (doc) => {
      if (doc.exists()) {
        setCurrentUserId(doc.data().userId || null);
      }
    });

    return () => unsubscribe();
  }, []);

  // Manejar la búsqueda
  const handleSearch = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  // Confirmar selección de usuario
  const confirmSelectUser = (user) => {
    setTempUser(user);
    setShowPopup(true);
  };

  // Manejar la selección final de un usuario
  const handleSelectUser = async () => {
    try {
      const livesettingsDocRef = doc(db, "Livesettings", "hermosillo");
      await updateDoc(livesettingsDocRef, { userId: tempUser.id });
      setSelectedUser(tempUser);
      setShowPopup(false);
      alert("¡Usuario actualizado exitosamente!");
    } catch (error) {
      console.error("Error updating Livesettings:", error);
    }
  };

  // Filtrar usuarios según el término de búsqueda
  const filteredUsers = users.filter((user) => {
    if (!user.name) return false; // Validación adicional
    return user.name.toLowerCase().includes(searchTerm);
  });

  return (
    <div className="p-4 bg-gray-50 min-h-screen flex flex-col">
      <h2 className="text-2xl font-bold text-center mb-6">Seleccionar Rider</h2>

      {/* Campo de búsqueda */}
      <input
        type="text"
        placeholder="Buscar por nombre..."
        value={searchTerm}
        onChange={handleSearch}
        className="w-full px-4 py-3 mb-4 border border-gray-300 rounded-full focus:outline-none focus:ring focus:ring-blue-400 text-sm"
      />

      {/* Lista de usuarios */}
      <ul className="flex flex-col gap-3 overflow-y-auto">
        {filteredUsers.map((user) => (
          <li
            key={user.id}
            className={`flex items-center gap-4 p-3 rounded-lg shadow-sm border hover:bg-gray-100 cursor-pointer ${
              currentUserId === user.id ? "bg-green-100 border-green-400" : ""
            }`}
            onClick={() => confirmSelectUser(user)}
          >
            <span className="flex-grow font-medium text-gray-700 truncate">
              {user.name}
            </span>
            {currentUserId === user.id && (
              <span className="text-green-500 text-xs font-semibold">
                Seleccionado
              </span>
            )}
          </li>
        ))}
      </ul>

      {/* Usuario seleccionado */}
      {selectedUser && (
        <div className="mt-6 bg-blue-100 rounded-lg p-4 text-center shadow">
          <h3 className="text-lg font-bold text-blue-600 mb-2">
            Usuario seleccionado
          </h3>
          <img
            src={selectedUser.photoUrl}
            alt={selectedUser.name}
            className="w-20 h-20 rounded-full object-cover mx-auto border mb-2"
          />
          <p className="text-gray-700 font-medium">{selectedUser.name}</p>
        </div>
      )}

      {/* Popup de Confirmación */}
      {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-6 w-11/12 max-w-sm text-center">
            <h3 className="text-lg font-semibold mb-4">
              ¿Estás seguro de que quieres seleccionar a este rider?
            </h3>
            <img
              src={tempUser.photoUrl}
              alt={tempUser.name}
              className="w-16 h-16 rounded-full object-cover mx-auto border mb-4"
            />
            <p className="text-gray-700 font-medium mb-6">{tempUser.name}</p>
            <div className="flex justify-between gap-4">
              <button
                onClick={() => setShowPopup(false)}
                className="flex-1 px-4 py-2 bg-gray-200 rounded-md text-gray-600 hover:bg-gray-300"
              >
                Cancelar
              </button>
              <button
                onClick={handleSelectUser}
                className="flex-1 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
              >
                Confirmar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RiderSelect;
