import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { doc, onSnapshot, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { Coins } from "lucide-react";
import axios from "axios";

const SpeedBalanceCheck = ({
  userId,
  orderId,
  orderTotal,
  onBalanceUpdate,
}) => {
  const [balance, setBalance] = useState(0);
  const [loading, setLoading] = useState(true);
  const [isPaying, setIsPaying] = useState(false);
  const navigate = useNavigate(); // Ensure navigate is available in this component

  useEffect(() => {
    if (!userId) return;

    const userDocRef = doc(db, "Users", userId);

    const unsubscribe = onSnapshot(
      userDocRef,
      (doc) => {
        if (doc.exists()) {
          const userData = doc.data();
          const userBalance = Number(userData.balance) || 0;
          setBalance(userBalance); // Update balance state
          onBalanceUpdate(userBalance); // Communicate balance to parent
        } else {
          toast.error("No se encontró el documento del usuario.");
        }
        setLoading(false);
      },
      (error) => {
        console.error("Error al obtener el balance del usuario:", error);
        toast.error(
          "Error al obtener el balance de SpeedCoins. Intente nuevamente."
        );
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [userId, onBalanceUpdate]);

  const handlePayWithSpeedCoins = async () => {
    if (balance < orderTotal) {
      toast.error("No tienes suficientes SpeedCoins para realizar este pago.");
      return;
    }

    setIsPaying(true);
    try {
      const response = await axios.post(
        "https://heroic-truck-437503-j4.wl.r.appspot.com/api/payments/create",
        { orderId, paymentMethod: 1 }
      );

      if (response.status === 201) {
        const newBalance = balance - orderTotal;
        await updateDoc(doc(db, "Users", userId), { balance: newBalance });

        toast.success("¡Pago realizado con éxito usando SpeedCoins!");
        navigate(`/success/${orderId}`); // Redirect to ticket page after successful payment
      } else {
        toast.error("Hubo un problema al procesar el pago.");
      }
    } catch (error) {
      console.error("Error en la confirmación de pago con SpeedCoins:", error);
      toast.error("Error al procesar el pago. Intente nuevamente.");
    } finally {
      setIsPaying(false);
    }
  };

  if (loading) {
    return <div className="text-center">Cargando balance...</div>;
  }

  return (
    <div>
      <p className="text-gray-600">
        Balance de SpeedCoins: {balance.toFixed(2)}
      </p>
      {balance >= orderTotal ? (
        <button
          onClick={handlePayWithSpeedCoins}
          disabled={isPaying}
          className="w-full mt-4 py-3 rounded-lg bg-black text-white font-bold flex items-center justify-center gap-2 transition-transform transform active:scale-95"
        >
          <Coins className="w-5 h-5" />{" "}
          {isPaying
            ? "Procesando..."
            : `Pagar $${orderTotal.toFixed(2)} con SpeedCoins`}
        </button>
      ) : (
        <p className="text-red-600 mt-2">
          No tienes suficientes SpeedCoins para realizar este pago.
        </p>
      )}
    </div>
  );
};

export default SpeedBalanceCheck;
