import React from "react";

const TeamMembers = () => {
  const members = [
    {
      name: "Pedro Aguirre",
      role: "Founder & Software Developer",
      avatar:
        "https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/1684958269703.jpeg?alt=media&token=deb2c747-6d17-445e-9676-32e2172af0f1",
      description:
        "Mi objetivo es crear un entorno donde la tecnología y la creatividad se combinen para superar las expectativas del usuario final.",
      linkedin: "https://www.linkedin.com/in/pedroaguirre/",
    },
    {
      name: "Gerardo Carrizosa",
      role: "CO Founder, Software Engineer & Full-stack Developer",
      avatar:
        "https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/1675785281492.jpeg?alt=media&token=0c4527f3-67e4-4da5-850f-91d111f4dca9",
      description:
        "Experto en Arquitectura de software. Apasionado por la tecnología y el aprendizaje continuo.",
      linkedin: "https://www.linkedin.com/in/gerardocarrizosa/",
    },
    {
      name: "Guillermo Alvarez",
      role: "CTO & Project Manager",
      avatar:
        "https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/WhatsApp%20Image%202024-06-06%20at%2015.52.25.jpeg?alt=media&token=f44268f5-be8f-4e0c-a8ec-0c4de6ffaabf",
      description:
        "Trabajo en la interfaz entre el equipo de desarrollo, los clientes y los socios comerciales para definir las características y la dirección del producto.",
      linkedin: "https://www.linkedin.com/in/guillermoalvarez/",
    },
    {
      name: "Angel Armando Gutierrez",
      role: "Design Engineer",
      avatar:
        "https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/Home%2FScreenshot%202024-10-14%20at%2011.13.54%E2%80%AFa.m..png?alt=media&token=a624db17-be45-4801-b9c5-778194623988",
      description:
        "Me dedico a optimizar productos y crear diseños robustos, fáciles de usar y listos para la fabricación, asegurando eficiencia y calidad en cada etapa del desarrollo.",
      linkedin: "https://www.linkedin.com/in/angelarmando/",
    },
  ];

  return (
    <div className="bg-white py-16 px-4 sm:px-6 lg:px-8">
      <h2 className="text-4xl font-extrabold text-center text-gray-900 sm:text-5xl">
        Nuestro Equipo
      </h2>
      <p className="mt-4 text-lg text-center text-gray-600">
        Las mentes detrás de nuestra visión.
      </p>
      <div className="mt-12 grid gap-8 sm:grid-cols-2 lg:grid-cols-4">
        {members.map((member) => (
          <div
            key={member.name}
            className="bg-black text-white rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300 p-6 text-center"
          >
            <img
              src={member.avatar}
              alt={member.name}
              className="w-32 h-32 rounded-full mx-auto mb-4 object-cover border-4 border-gray-300"
            />
            <h3 className="text-xl font-bold">{member.name}</h3>
            <p className="text-sm text-gray-400">{member.role}</p>
            <p className="mt-4 text-sm text-gray-300">{member.description}</p>
            <a
              href={member.linkedin}
              target="_blank"
              rel="noopener noreferrer"
              className="mt-6 inline-flex items-center justify-center space-x-2 text-blue-400 hover:text-blue-500 transition duration-200"
            >
              <img
                src="https://cdn-icons-png.flaticon.com/512/174/174857.png"
                alt="LinkedIn"
                className="w-5 h-5"
              />
              <span className="text-sm font-medium">LinkedIn</span>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TeamMembers;
