import { initMercadoPago } from "@mercadopago/sdk-react";

export class MercadoPago {
  publicKey = "APP_USR-0ef7f438-b1b0-4fcc-8229-15a28e563087";

  constructor() {
    initMercadoPago(this.publicKey, {
      locale: "es-MX",
    });
  }
}
