import React from "react";
import GetInTouch from "../components/Home/GetInTouch";
import GetStarted from "../components/Home/GetStarted";
import Header from "../components/Home/Header";
import Footer from "../components/Home/Footer";
import LiveStats from "../components/Home/LiveStats";
import Features from "./Home/Features";
import TeamMembers from "./Home/TeamMembers";
import { useUserAuth } from "../context/userAuthContext";
import { useNavigate } from "react-router-dom";
import LiveStream from "./Home/LiveStream";

const Welcome = () => {
  const navigate = useNavigate();
  const { user } = useUserAuth();

  if (user !== null) {
    navigate("home");
    return;
  }

  return (
    <React.Fragment>
      <Header />
      <LiveStats />
      <LiveStream />
      <GetStarted />
      <Features />
      <GetInTouch />
      <TeamMembers />
      <Footer />
    </React.Fragment>
  );
};

export default Welcome;
