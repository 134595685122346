import axios from "axios";
import { MercadoPago } from "./mp.config";
import { CardPayment } from "@mercadopago/sdk-react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

function MpCheckout({ amount, orderId, onFinish }) {
  const navigate = useNavigate();
  const instance = new MercadoPago(orderId);

  if (!instance) return <div>no se pudo carnal!</div>;

  async function onSubmit(formData) {
    try {
      if (!orderId) throw new Error("No orderId in Mercadopago submit");

      const requestBody = {
        ...formData,
        orderId,
      };
      const paymentResponse = await axios.post(
        "https://heroic-truck-437503-j4.wl.r.appspot.com/api/payments/create",
        requestBody
      );

      if (paymentResponse.data.status === "approved") {
        const responseData = paymentResponse.data;
        toast.success("¡Tu inscripción ha sido exitosa!");

        if (onFinish) {
          onFinish();
        } else {
          setTimeout(() => {
            navigate(`/event/${responseData.itemId}`);
          }, 1000);
        }
      }
    } catch (error) {
      console.log("onSubmit | error =>", error);
      throw error;
    }
  }

  function onError(error) {
    // callback llamado para todos los casos de error de Brick
    toast.error("Error al procesar tu pago. ¡Intenta de nuevo!");
    // alert("Error al procesar tu pago. ¡Intenta de nuevo!");
  }

  function onReady() {
    /*
      Callback llamado cuando Brick está listo.
      Aquí puedes ocultar cargamentos de su sitio, por ejemplo.
    */
  }

  return (
    <CardPayment
      initialization={{
        amount,
      }}
      onSubmit={onSubmit}
      onReady={onReady}
      onError={onError}
    />
  );
}

export default MpCheckout;
