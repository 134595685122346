import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../../firebase";
import {
  collection,
  query,
  where,
  onSnapshot,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";
import {
  Button,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tabs,
  Tab,
} from "@mui/material";
import EventLeaderboardUI from "./EventLeaderboardUI";
import ComparePopup from "./ComparePopup";
import PersonIcon from "@mui/icons-material/Person";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import FilterListIcon from "@mui/icons-material/FilterList";

const EventLiveLeaderboard = () => {
  const { uid } = useParams();
  const [runs, setRuns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [ownerData, setOwnerData] = useState({});
  const [isCompareOpen, setIsCompareOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [currentTrack, setCurrentTrack] = useState(0);
  const [trackToShow, setTrackToShow] = useState(null);
  const [categoryToShow, setCategoryToShow] = useState(null);
  const [totalTimes, setTotalTimes] = useState([]);

  useEffect(() => {
    const fetchLiveSettings = async () => {
      const settingsRef = doc(db, "LiveSettings", "Hermosillo");
      const settingsSnap = await getDoc(settingsRef);
      if (settingsSnap.exists()) {
        const { trackId, categoryId } = settingsSnap.data();
        setTrackToShow(trackId);
        setCategoryToShow(categoryId);
      }
    };

    const fetchEventData = async () => {
      const categoriesRef = collection(db, `events/${uid}/categories`);
      const categoriesSnap = await getDocs(categoriesRef);
      const categoriesData = categoriesSnap.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCategories(categoriesData);

      const participantsRef = collection(db, `events/${uid}/participants`);
      const participantsSnap = await getDocs(participantsRef);
      const participantsData = participantsSnap.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setParticipants(participantsData);
    };

    fetchLiveSettings();
    fetchEventData();
  }, [uid]);

  useEffect(() => {
    const fetchRuns = async () => {
      if (!trackToShow || !categoryToShow) return;

      const runsQuery = query(
        collection(db, "Runs"),
        where("EventID", "==", uid),
        where("TrackID", "==", trackToShow)
      );

      const unsubscribe = onSnapshot(runsQuery, async (snapshot) => {
        const runsData = snapshot.docs
          .map((doc) => ({ id: doc.id, ...doc.data() }))
          .filter((run) => run.ElapsedTime);

        const sortedRuns = runsData.sort(
          (a, b) =>
            convertTimeToMilliseconds(a.ElapsedTime) -
            convertTimeToMilliseconds(b.ElapsedTime)
        );

        const bestTimesByUser = sortedRuns.reduce((acc, run) => {
          const key = `${run.NFCIdentifier}-${run.TrackID}`;
          if (
            !acc[key] ||
            convertTimeToMilliseconds(run.ElapsedTime) <
              convertTimeToMilliseconds(acc[key].ElapsedTime)
          ) {
            acc[key] = run;
          }
          return acc;
        }, {});

        const uniqueRuns = Object.values(bestTimesByUser);

        const ownerPromises = uniqueRuns.map(async (run) => {
          const tagsQuery = query(
            collection(db, "Tags"),
            where("NFCID", "==", run.NFCIdentifier)
          );
          const tagsSnapshot = await getDocs(tagsQuery);

          if (!tagsSnapshot.empty) {
            const ownerUID = tagsSnapshot.docs[0].data().Owner;
            if (ownerUID === "") {
              return {
                NFCIdentifier: run.NFCIdentifier,
                Owner: "Desconocido",
                PhotoUrl: "/default-avatar.jpg",
                categoryId: null,
              };
            }
            const participant = participants.find((p) => p.userId === ownerUID);
            const categoryId = participant ? participant.categoryId : null;

            const userDocRef = doc(db, "Users", ownerUID);
            const userDoc = await getDoc(userDocRef);

            if (!userDoc.empty) {
              const userData = userDoc.data();
              return {
                NFCIdentifier: run.NFCIdentifier,
                Owner: userData.name,
                PhotoUrl: userData.photoUrl || "/default-avatar.jpg",
                categoryId: categoryId,
              };
            }
          }

          const userDocRef = doc(db, "Users", run.NFCIdentifier);
          const userDoc = await getDoc(userDocRef);

          if (userDoc.exists()) {
            const userData = userDoc.data();
            const ownerUID = userDoc.id;
            const participant = participants.find((p) => p.userId === ownerUID);
            const categoryId = participant ? participant.categoryId : null;

            return {
              NFCIdentifier: run.NFCIdentifier,
              Owner: userData.name,
              PhotoUrl: userData.photoUrl || "/default-avatar.jpg",
              categoryId: categoryId,
            };
          }

          return {
            NFCIdentifier: run.NFCIdentifier,
            Owner: "Desconocido",
            PhotoUrl: "/default-avatar.jpg",
            categoryId: null,
          };
        });

        const ownerResults = await Promise.all(ownerPromises);
        const ownerDataMap = ownerResults.reduce((acc, ownerResult) => {
          acc[ownerResult.NFCIdentifier] = {
            Owner: ownerResult.Owner,
            PhotoUrl: ownerResult.PhotoUrl,
            categoryId: ownerResult.categoryId,
          };
          return acc;
        }, {});

        setOwnerData(ownerDataMap);
        setRuns(uniqueRuns);
        setLoading(false);
      });

      return () => unsubscribe();
    };

    if (trackToShow && categoryToShow && participants.length > 0) {
      fetchRuns();
    }
  }, [uid, trackToShow, categoryToShow, participants]);

  const convertTimeToMilliseconds = (time) => {
    if (!time || time === "---" || time === "DNF") return null;
    try {
      const [hours, minutes, seconds] = time.split(":");
      const [secs, ms] = seconds.split(".");
      return (
        parseInt(hours) * 3600000 +
        parseInt(minutes) * 60000 +
        parseInt(secs) * 1000 +
        parseInt(ms)
      );
    } catch (error) {
      console.error("Error converting time:", time, error);
      return 0;
    }
  };

  const getFilteredRuns = () => {
    return runs.filter((run) => {
      const ownerInfo = ownerData[run.NFCIdentifier];
      const matchesCategory =
        selectedCategory === "all" ||
        (ownerInfo && ownerInfo.categoryId === selectedCategory);
      return matchesCategory;
    });
  };

  if (!trackToShow || !categoryToShow)
    return (
      <div className="flex justify-center items-center h-screen bg-gray-100">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-black"></div>
      </div>
    );

  return (
    <div>
      <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 3 }}>
        <Tabs value={0} variant="scrollable" scrollButtons="auto">
          <Tab label="Filtrado" />
        </Tabs>
      </Box>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
        px={2}
      >
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel>Categoría</InputLabel>
          <Select
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            label="Categoría"
            startAdornment={<FilterListIcon sx={{ mr: 1 }} />}
          >
            <MenuItem value="all">Selecciona</MenuItem>
            {categories.map((category) => (
              <MenuItem key={category.id} value={category.id}>
                {category.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button
          variant="contained"
          sx={{
            backgroundColor: "#000",
            color: "#fff",
            padding: "10px 20px",
            borderRadius: "30px",
            textTransform: "none",
            "&:hover": { backgroundColor: "#333" },
          }}
          onClick={() => setIsCompareOpen(true)}
          startIcon={<PersonIcon />}
          endIcon={<PersonIcon />}
        >
          <CompareArrowsIcon sx={{ fontSize: "24px", mx: 1 }} />
          Comparate
        </Button>
      </Box>

      <ComparePopup
        open={isCompareOpen}
        onClose={() => setIsCompareOpen(false)}
        runs={getFilteredRuns()}
        ownerData={ownerData}
      />

      <EventLeaderboardUI
        runs={getFilteredRuns()}
        ownerData={ownerData}
        loading={loading}
      />
    </div>
  );
};

export default EventLiveLeaderboard;
