import React, { useState, useEffect, useRef } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import { Typography, Grid, Avatar, Box } from "@mui/material";
import ParkIcon from "@mui/icons-material/Park";
import GroupIcon from "@mui/icons-material/Group";
import LoopIcon from "@mui/icons-material/Loop";
import RouteIcon from "@mui/icons-material/Route";
import CountUp from "react-countup";

const StatItem = ({ icon, count, label, start }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
      p: { xs: 2, sm: 3 },
      backgroundColor: "white",
      borderRadius: "15px",
      boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.15)",
      transition: "transform 0.3s ease, box-shadow 0.3s ease",
      "&:hover": {
        transform: "scale(1.05)",
        boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.25)",
      },
      width: "100%",
      maxWidth: "200px",
    }}
  >
    <Avatar
      sx={{
        mb: 1.5,
        backgroundColor: "transparent",
        color: "#333",
        width: 64,
        height: 64,
        border: "2px solid #ddd",
      }}
    >
      {icon}
    </Avatar>
    <Typography
      variant="h5"
      sx={{
        fontWeight: "bold",
        color: "#333",
        fontSize: "1.75rem",
        textShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)",
      }}
    >
      <CountUp start={start ? 0 : count} end={count} duration={2} />
    </Typography>
    <Typography
      variant="body2"
      sx={{
        color: "#555",
        fontSize: "0.9rem",
        fontWeight: "500",
        mt: 0.5,
        textTransform: "uppercase",
        letterSpacing: "0.1em",
      }}
    >
      {label}
    </Typography>
  </Box>
);

const LiveStats = () => {
  const [parksCount, setParksCount] = useState(0);
  const [runsCount, setRunsCount] = useState(0);
  const [usersCount, setUsersCount] = useState(0);
  const [tracksCount, setTracksCount] = useState(0);
  const [startCount, setStartCount] = useState(false);
  const statsRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const parksCollectionRef = collection(db, "Parks");
        const parksSnapshot = await getDocs(parksCollectionRef);
        setParksCount(parksSnapshot.size);

        let totalTracksCount = 0;
        for (const parkDoc of parksSnapshot.docs) {
          const tracksCollectionRef = collection(parkDoc.ref, "Tracks");
          const tracksSnapshot = await getDocs(tracksCollectionRef);
          totalTracksCount += tracksSnapshot.size;
        }
        setTracksCount(totalTracksCount);

        const runsCollectionRef = collection(db, "Runs");
        const runsSnapshot = await getDocs(runsCollectionRef);
        setRunsCount(runsSnapshot.size);

        const usersCollectionRef = collection(db, "Users");
        const usersSnapshot = await getDocs(usersCollectionRef);
        setUsersCount(usersSnapshot.size);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setStartCount(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    if (statsRef.current) {
      observer.observe(statsRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <Box
      ref={statsRef}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        overflow: "hidden",
        py: 6,
        px: 2,
        backgroundColor: "#f7f9fc",
        backgroundImage:
          "radial-gradient(circle at top, rgba(255, 255, 255, 0.7), rgba(247, 249, 252, 1))",
      }}
    >
      <Typography
        variant="h5"
        sx={{
          fontWeight: "bold",
          color: "#333",
          mb: 4,
          textAlign: "center",
          fontSize: { xs: "1.5rem", md: "2rem" },
          textShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        ¡Estadísticas en tiempo real!
      </Typography>
      <Grid
        container
        spacing={3}
        sx={{
          justifyContent: "center",
          width: { xs: "100%", sm: "90%", lg: "80%" },
        }}
      >
        <Grid item xs={6} sm={4} md={3}>
          <StatItem
            icon={<ParkIcon fontSize="large" />}
            count={parksCount}
            label="Parques"
            start={startCount}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <StatItem
            icon={<RouteIcon fontSize="large" />}
            count={tracksCount}
            label="Pistas"
            start={startCount}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <StatItem
            icon={<LoopIcon fontSize="large" />}
            count={runsCount}
            label="Vueltas"
            start={startCount}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <StatItem
            icon={<GroupIcon fontSize="large" />}
            count={usersCount}
            label="Usuarios"
            start={startCount}
          />
        </Grid>
      </Grid>
      <Typography
        variant="caption"
        sx={{
          color: "#777",
          mt: 4,
          textAlign: "center",
          fontSize: "0.8rem",
        }}
      >
        Datos recopilados automáticamente.
      </Typography>
    </Box>
  );
};

export default LiveStats;
