import React, { useState, useEffect } from "react";
import { db } from "../../firebase";
import {
  collection,
  query,
  where,
  onSnapshot,
  doc,
  getDoc,
  getDocs,
} from "firebase/firestore";
import { MapPin, Flag, Timer } from "lucide-react";

const ActiveLive = () => {
  const [activeRun, setActiveRun] = useState(null);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  useEffect(() => {
    const updateCurrentTime = () => {
      setCurrentTime(new Date());
      requestAnimationFrame(updateCurrentTime);
    };
    requestAnimationFrame(updateCurrentTime);
  }, []);

  const getUserName = async (userID) => {
    try {
      const userDoc = await getDoc(doc(db, "Users", userID));
      return userDoc.exists() ? userDoc.data().name : "Unknown";
    } catch (error) {
      console.error("Error getting user data:", error);
      return "Unknown";
    }
  };

  const getParkName = async (parkID) => {
    try {
      const parkDoc = await getDoc(doc(db, "Parks", parkID));
      return parkDoc.exists() ? parkDoc.data().ParkName : "Unknown Park";
    } catch (error) {
      console.error("Error getting park data:", error);
      return "Unknown Park";
    }
  };

  const getTrackName = async (parkID, trackID) => {
    try {
      const trackDoc = await getDoc(
        doc(db, "Parks", parkID, "Tracks", trackID)
      );
      return trackDoc.exists() ? trackDoc.data().TrackName : "Unknown Track";
    } catch (error) {
      console.error("Error getting track data:", error);
      return "Unknown Track";
    }
  };

  const getUserPhotoUrl = async (userID) => {
    try {
      const userDoc = await getDoc(doc(db, "Users", userID));
      return userDoc.exists() ? userDoc.data().photoUrl : null;
    } catch (error) {
      console.error("Error getting user photo URL:", error);
      return null;
    }
  };

  const fetchActiveRun = async () => {
    try {
      const liveSettingsDocRef = doc(db, "Livesettings", "hermosillo");
      onSnapshot(liveSettingsDocRef, async (liveSettingsDoc) => {
        if (!liveSettingsDoc.exists()) {
          console.error("Documento 'hermosillo' no encontrado en Livesettings");
          setActiveRun(null);
          return;
        }

        const userId = liveSettingsDoc.data().userId;

        const q = query(collection(db, "Runs"), where("ActiveRun", "==", true));
        onSnapshot(q, async (querySnapshot) => {
          let foundActiveRun = false;

          for (const runDoc of querySnapshot.docs) {
            const runData = runDoc.data();

            const tagQuery = query(
              collection(db, "Tags"),
              where("NFCID", "==", runData.NFCIdentifier)
            );
            const tagSnapshot = await getDocs(tagQuery);

            if (!tagSnapshot.empty) {
              const tagData = tagSnapshot.docs[0].data();

              if (tagData.Owner === userId) {
                runData.Owner = await getUserName(tagData.Owner);
                runData.ParkName = await getParkName(runData.ParkID);
                runData.TrackName = await getTrackName(
                  runData.ParkID,
                  runData.TrackID
                );
                runData.photoUrl = await getUserPhotoUrl(tagData.Owner);

                setActiveRun({ ...runData, timestamp: runDoc.id });
                foundActiveRun = true;
                break;
              }
            }
          }

          if (!foundActiveRun) {
            const ownerName = await getUserName(userId);
            const photoUrl = await getUserPhotoUrl(userId);
            setActiveRun({ Owner: ownerName, photoUrl });
          }
        });
      });
    } catch (error) {
      console.error("Error fetching active run:", error);
      setActiveRun(null);
    }
  };

  useEffect(() => {
    fetchActiveRun();
  }, []);

  const calculateElapsedTime = (startTime) => {
    const startTimestamp = new Date(startTime).getTime();
    const currentTimestamp = currentTime.getTime();
    const elapsedMilliseconds = currentTimestamp - startTimestamp;
    const minutes = Math.floor(elapsedMilliseconds / 60000);
    const seconds = Math.floor((elapsedMilliseconds % 60000) / 1000);
    const milliseconds = elapsedMilliseconds % 1000;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}.${milliseconds.toString().padStart(3, "0")}`;
  };

  return (
    <div
      className={`w-full p-6 ${
        isVisible ? "opacity-100 transition-opacity duration-500" : "opacity-0"
      }`}
    >
      <div className="group relative overflow-hidden rounded-xl bg-gradient-to-br from-purple-900 via-indigo-900 to-blue-900 transition-transform duration-300 hover:scale-105 hover:shadow-2xl">
        <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent z-10" />
        <div className="flex h-full">
          {/* Imagen del corredor */}
          <div className="w-1/3 flex items-center justify-center">
            <div className="relative h-48 w-48 max-w-full max-h-full">
              <img
                className="w-full h-full object-cover"
                src={
                  activeRun?.photoUrl ||
                  "https://cdn.vectorstock.com/i/500p/08/19/gray-photo-placeholder-icon-design-ui-vector-35850819.jpg"
                }
                alt={activeRun?.Owner || "Usuario desconocido"}
              />
              <div className="absolute inset-0 bg-gradient-to-r from-transparent to-purple-900/40" />
            </div>
          </div>

          {/* Contenido */}
          <div className="w-2/3 p-6 flex flex-col justify-center z-20">
            <div>
              <h3 className="text-3xl font-bold text-white mb-2 tracking-wider">
                {activeRun?.Owner?.length > 17
                  ? `${activeRun.Owner.substring(0, 17)}...`
                  : activeRun?.Owner || "Usuario desconocido"}
              </h3>
              {activeRun?.StartTime ? (
                <div className="space-y-2">
                  <div className="flex items-center text-gray-200">
                    <MapPin className="w-5 h-5 text-cyan-400 mr-2 flex-shrink-0" />
                    <span className="text-sm truncate">
                      {activeRun.ParkName}
                    </span>
                    <Flag className="w-5 h-5 text-pink-400 mx-2 flex-shrink-0" />
                    <span className="text-sm truncate">
                      {activeRun.TrackName}
                    </span>
                  </div>
                  <div className="mt-4 flex items-center">
                    <Timer className="w-6 h-6 text-emerald-400 mr-2 animate-pulse" />
                    <span className="text-3xl font-mono font-bold text-white">
                      {calculateElapsedTime(activeRun.StartTime)}
                    </span>
                  </div>
                </div>
              ) : (
                <p className="text-lg text-gray-300 font-semibold">
                  ¡Próximo corredor!
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/10 to-transparent -translate-x-full group-hover:translate-x-full transition-transform duration-1000" />
      </div>
    </div>
  );
};

export default ActiveLive;
