import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Typed from "typed.js";
import { Volume2, VolumeX } from "lucide-react";

const Header = () => {
  const typedElement = useRef(null);
  const videoRef = useRef(null);
  const [isMuted, setIsMuted] = useState(true);

  useEffect(() => {
    const typed = new Typed(typedElement.current, {
      strings: ["Hobby", "Evento", "Aventura", "Parque", "Venta"],
      typeSpeed: 100,
      backSpeed: 50,
      loop: true,
    });

    return () => {
      typed.destroy();
    };
  }, []);

  const handleVideoClick = () => {
    setIsMuted(!isMuted);
    if (videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted;
    }
  };

  return (
    <header className="relative min-h-screen w-full overflow-hidden flex items-center justify-center">
      {/* Video de Fondo */}
      <div className="absolute inset-0 -z-10 overflow-hidden">
        <video
          ref={videoRef}
          src="https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/stories%2F4lh37bi7FjWvHVvK9GIMLDy6TX53_1730413988831_Snapinsta.app_video_7040AD1462F56D43F93FCCC2E5C31798_video_dashinit.mp4?alt=media&token=ed69249b-8646-42d3-8299-eb495e4eb12f"
          autoPlay
          loop
          muted
          className="absolute inset-0 w-full h-full object-cover"
        />
        {/* Botón de Volumen */}
        <button
          onClick={handleVideoClick}
          className="absolute top-4 right-4 bg-black bg-opacity-50 text-white p-2 rounded-full hover:bg-opacity-70 transition"
        >
          {isMuted ? (
            <VolumeX className="h-6 w-6" />
          ) : (
            <Volume2 className="h-6 w-6" />
          )}
        </button>
      </div>

      {/* Capa de Transparencia */}
      <div className="absolute inset-0 bg-black/50 -z-10"></div>

      {/* Contenido Centralizado */}
      <div className="relative flex flex-col items-center text-center text-white h-full px-6 justify-center">
        <div className="max-w-2xl">
          {/* Títulos */}
          <h1 className="text-4xl md:text-6xl font-extrabold tracking-tight leading-tight mb-6 drop-shadow-lg">
            Elevamos tu
            <br />
            <span
              ref={typedElement}
              className="text-yellow-400 font-bold drop-shadow-md"
            ></span>
          </h1>
          <p className="text-lg md:text-xl mb-8 max-w-lg mx-auto drop-shadow-md">
            Mide, compite y mejora en tiempo real con la máxima precisión y
            análisis de inteligencia artificial.
          </p>
          {/* Botones */}
          <div className="flex flex-col md:flex-row gap-4 items-center justify-center">
            <Link
              to="/Login"
              className="block w-full md:w-auto text-center text-lg font-medium py-3 px-6 rounded-lg bg-black text-white hover:bg-gray-800 transition transform hover:scale-105"
            >
              Inicia Sesión
            </Link>
            <Link
              to="/SignUp"
              className="block w-full md:w-auto text-center text-lg font-medium py-3 px-6 rounded-lg border-2 border-white text-white hover:bg-white hover:text-black transition transform hover:scale-105"
            >
              Regístrate
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
