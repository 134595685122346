import React, { useState, useEffect } from "react";
import {
  collection,
  onSnapshot,
  doc,
  getDoc,
  getDocs,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { Container } from "@mui/material";
import { useLoadScript } from "@react-google-maps/api";
import RidesFiltersAndNavigation from "./RidesFiltersAndNavigation";
import RidesList from "./RidesList";

const NewRides = () => {
  const [rides, setRides] = useState([]);
  const [userLocation, setUserLocation] = useState(null);
  const [filteredRides, setFilteredRides] = useState([]);
  const [filterDate, setFilterDate] = useState("");
  const [filterBikeType, setFilterBikeType] = useState("");
  const [availableBikeTypes, setAvailableBikeTypes] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBSXE8zZ-SqMWdDz16hr00Djvkm3mMit28",
  });

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => {
          console.error("Error obtaining user location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  useEffect(() => {
    const fetchRides = async () => {
      const ridesRef = collection(db, "Rides");
      onSnapshot(ridesRef, async (snapshot) => {
        const bikeTypesSet = new Set();
        const ridesList = snapshot.docs.map(async (doc) => {
          const rideData = doc.data();
          const rideTime = rideData.time ? new Date(rideData.time) : null;
          if (!rideTime || rideTime < new Date()) {
            return null;
          }
          const creator = await getUser(rideData.creator);
          rideData.creator = creator;
          rideData.riders = await getRiders(rideData.riders);
          rideData.messagesCount = await getMessagesCount(doc.id);
          rideData.distance = userLocation
            ? calculateDistance(
                userLocation.latitude,
                userLocation.longitude,
                rideData.location.latitude,
                rideData.location.longitude
              )
            : null;
          bikeTypesSet.add(rideData.bikeType);
          return { ...rideData, id: doc.id };
        });
        const resolvedRides = await Promise.all(ridesList);
        const validRides = resolvedRides.filter((ride) => ride !== null);
        validRides.sort((a, b) => a.distance - b.distance);
        setRides(validRides);
        setFilteredRides(validRides);
        setAvailableBikeTypes([...bikeTypesSet]);
      });
    };

    fetchRides();
  }, [userLocation]);

  useEffect(() => {
    filterRides();
  }, [filterDate, filterBikeType, searchTerm]);

  const filterRides = () => {
    let filtered = rides;

    if (filterDate) {
      filtered = filtered.filter((ride) => {
        const rideDate = new Date(ride.time).toISOString().split("T")[0];
        return rideDate === filterDate;
      });
    }

    if (filterBikeType) {
      filtered = filtered.filter((ride) => ride.bikeType === filterBikeType);
    }

    if (searchTerm) {
      const lowerCaseSearchTerm = searchTerm.toLowerCase();
      filtered = filtered.filter(
        (ride) =>
          ride.name.toLowerCase().includes(lowerCaseSearchTerm) ||
          ride.creator?.name.toLowerCase().includes(lowerCaseSearchTerm)
      );
    }

    setFilteredRides(filtered);
  };

  const getUser = async (userId) => {
    const userRef = doc(db, "Users", userId);
    const userSnap = await getDoc(userRef);
    if (userSnap.exists()) {
      return { id: userSnap.id, ...userSnap.data() };
    } else {
      return null;
    }
  };

  const getRiders = async (ridersIds) => {
    const ridersList = [];
    for (const riderId of ridersIds) {
      const rider = await getUser(riderId);
      if (rider) {
        ridersList.push(rider);
      }
    }
    return ridersList;
  };

  const getMessagesCount = async (rideId) => {
    try {
      const chatRef = collection(db, "Rides", rideId, "chat");
      const chatSnapshot = await getDocs(chatRef);
      return chatSnapshot.size;
    } catch (error) {
      console.error("Error fetching messages count:", error);
      return 0;
    }
  };

  const handleRideClick = (rideId) => {
    navigate(`/rides/${rideId}`);
  };

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const toRadians = (degree) => degree * (Math.PI / 180);

    const R = 6371; // Radius of the Earth in km
    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadians(lat1)) *
        Math.cos(toRadians(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  };

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <Container>
      <RidesFiltersAndNavigation
        filterDate={filterDate}
        setFilterDate={setFilterDate}
        filterBikeType={filterBikeType}
        setFilterBikeType={setFilterBikeType}
        availableBikeTypes={availableBikeTypes}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
      />
      <RidesList rides={filteredRides} handleRideClick={handleRideClick} />
    </Container>
  );
};

export default NewRides;
