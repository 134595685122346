import React from "react";
import { useNavigate } from "react-router-dom";

const LivePanel = () => {
  const navigate = useNavigate();

  // Función para manejar la navegación
  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen flex flex-col items-center justify-center">
      <h1 className="text-4xl font-extrabold mb-12 text-center text-gray-800">
        Panel en Vivo
      </h1>

      <div className="space-y-6 w-full max-w-lg">
        {/* Botón para ir a RiderSelect */}
        <button
          onClick={() => handleNavigation("/liveriderselect")}
          className="w-full px-6 py-4 text-lg font-semibold text-white bg-blue-500 rounded-xl shadow-lg hover:bg-blue-600 focus:outline-none focus:ring-4 focus:ring-blue-300 transition-all"
        >
          Seleccionar Rider
        </button>

        {/* Botón para ir a ActiveLive */}
        <button
          onClick={() => handleNavigation("/active-live")}
          className="w-full px-6 py-4 text-lg font-semibold text-white bg-green-500 rounded-xl shadow-lg hover:bg-green-600 focus:outline-none focus:ring-4 focus:ring-green-300 transition-all"
        >
          Ver Live Activo
        </button>

        {/* Botón para otro componente */}
        <button
          onClick={() => handleNavigation("/some-other-component")}
          className="w-full px-6 py-4 text-lg font-semibold text-white bg-purple-500 rounded-xl shadow-lg hover:bg-purple-600 focus:outline-none focus:ring-4 focus:ring-purple-300 transition-all"
        >
          Otro Componente
        </button>

        {/* Botón para Entrevista */}
        <button
          onClick={() => handleNavigation("/interview")}
          className="w-full px-6 py-4 text-lg font-semibold text-white bg-red-500 rounded-xl shadow-lg hover:bg-red-600 focus:outline-none focus:ring-4 focus:ring-red-300 transition-all"
        >
          Entrevista
        </button>
      </div>
    </div>
  );
};

export default LivePanel;
