import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useUserAuth } from "../../context/userAuthContext";
import {
  LocationOn,
  AccessTime,
  AttachMoney,
  CalendarMonth,
  Groups,
} from "@mui/icons-material";
import EventParticipants from "./EventParticipants";
import { eventsApi } from "../../lib/client";
import EventStats from "./EventStats";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EventChat from "./EventChat";

const EventDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { user } = useUserAuth();
  const [event, setEvent] = useState(null);
  const [userIsParticipant, setUserIsParticipant] = useState(false);
  const [showRegisterWarning, setShowRegisterWarning] = useState(false);

  useEffect(() => {
    const fetchEventData = async () => {
      const eventData = await eventsApi.getEventById(id, {
        categories: true,
        creator: true,
        participants: true,
      });

      if (user && eventData.participants) {
        const isParticipant = eventData.participants.some(
          (p) => p.user.uid === user.uid
        );
        setUserIsParticipant(isParticipant);
      }

      setEvent(eventData);
    };

    fetchEventData();
  }, [id, user]);

  const handleJoinEvent = () => {
    if (!user) {
      setShowRegisterWarning(true);
      return;
    }
    navigate(`/payevent/${id}`);
  };

  const handleLeaveEvent = async () => {
    try {
      await eventsApi.removeParticipantFromEvent(id, user.uid);
      setUserIsParticipant(false);
    } catch (error) {
      console.error("Error leaving event:", error);
    }
  };

  const handleShare = () => {
    const eventUrl = window.location.href;
    const shareData = {
      title: event.eventName,
      text: `${event.eventName}\nFecha: ${new Date(
        event.dateTime
      ).toLocaleDateString()} a las ${new Date(
        event.dateTime
      ).toLocaleTimeString()}\nCosto: ${
        event.price > 0 ? `$${event.price}` : "Gratis"
      }`,
      url: eventUrl,
    };

    if (navigator.share) {
      navigator
        .share(shareData)
        .catch((error) => console.error("Error sharing:", error));
    } else {
      navigator.clipboard.writeText(
        `${shareData.title}\n${shareData.text}\n${shareData.url}`
      );
      alert("¡Enlace y detalles copiados al portapapeles!");
    }
  };

  const handleSupport = () => {
    const eventUrl = window.location.href;
    const whatsappUrl = `https://wa.me/526622995258?text=Hola!%20Necesito%20soporte%20en%20${eventUrl}%20!!!`;
    window.open(whatsappUrl, "_blank");
  };

  if (!event) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-100">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-black"></div>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4 bg-gray-50 min-h-screen">
      {/* Event Details Section */}
      <div className="rounded-lg shadow-lg overflow-hidden mb-6 bg-white">
        <img
          src={event.photo}
          alt={event.eventName}
          className="object-cover w-full h-700 md:max-h-[700px] md:object-contain"
        />
        <div className="p-6">
          <h1 className="text-3xl font-bold text-gray-900 mb-4">
            {event.eventName}
          </h1>
          <OrganizerInfo creator={event.creator} />
          <EventInfo event={event} />
          <div className="mt-6 flex flex-col md:flex-row md:gap-4 gap-2">
            <ActionButtons
              event={event}
              userIsParticipant={userIsParticipant}
              onJoin={handleJoinEvent}
              onLeave={handleLeaveEvent}
              onShare={handleShare}
            />
            <button
              onClick={handleSupport}
              className="flex items-center justify-center py-2 px-4 bg-green-500 hover:bg-green-600 font-bold rounded-lg text-white gap-2 md:flex-grow"
            >
              <WhatsAppIcon fontSize="small" />
              <span>Soporte! Necesitas ayuda?</span>
            </button>
          </div>
        </div>
      </div>

      {/* Participants Section */}
      <div className="bg-gray-100 rounded-lg p-4 mb-6 shadow">
        <h2 className="text-lg font-semibold text-gray-800 mb-4 flex items-center">
          <Groups className="mr-2 text-gray-700" /> Participantes
        </h2>
        <EventParticipants
          participants={event.participants.map((p) => {
            const categoryFound = event.categories.find(
              (c) => c.id === p.categoryId
            );
            return {
              id: p.id,
              joinedAt: p.joinedAt,
              category: categoryFound.name,
              user: p.user,
            };
          })}
        />
      </div>

      {/* Event Stats Section */}
      <div className="mb-6">
        <EventChat uid={id} />
      </div>

      {/* Event Stats Section */}
      <div className="mb-6">
        <EventStats
          latitude={event.location.lat}
          longitude={event.location.lng}
          eventDate={event.dateTime}
        />
      </div>

      <RegisterDialog
        open={showRegisterWarning}
        onClose={() => setShowRegisterWarning(false)}
        onLogin={() => navigate("/login")}
        onSignup={() => navigate("/signup")}
      />
    </div>
  );
};

const OrganizerInfo = ({ creator }) => (
  <div className="flex items-center mb-4">
    <img
      src={creator?.photoUrl}
      alt={creator?.name}
      className="w-12 h-12 rounded-full mr-4"
    />
    <div>
      <p className="text-gray-500 text-sm">Organizado por</p>
      <p className="text-gray-900 font-semibold">
        {creator?.name || "Organizador"}
      </p>
    </div>
  </div>
);

const EventInfo = ({ event }) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
      <InfoItem
        icon={<CalendarMonth className="text-gray-700" />}
        label="Fecha"
        value={new Date(event.dateTime).toLocaleDateString()}
      />
      <InfoItem
        icon={<AccessTime className="text-gray-700" />}
        label="Hora"
        value={new Date(event.dateTime).toLocaleTimeString()}
      />
      <InfoItem
        icon={<LocationOn className="text-gray-700" />}
        label="Ubicación"
        value="Ver en mapa"
      />
      {event.price > 0 && (
        <InfoItem
          icon={<AttachMoney className="text-gray-700" />}
          label="Precio"
          value={
            <span className="bg-gray-900 text-white font-semibold px-2 py-1 rounded-md">
              ${event.price}
            </span>
          }
        />
      )}
      <div className="col-span-2">
        <p className="text-gray-500">Descripción</p>
        <p className="text-gray-800">{event.description}</p>
      </div>
    </div>
  );
};

const InfoItem = ({ icon, label, value }) => (
  <div className="flex items-center">
    <div className="mr-2">{icon}</div>
    <div>
      <p className="text-gray-500">{label}</p>
      <p className="text-gray-800 font-medium">{value}</p>
    </div>
  </div>
);

const ActionButtons = ({
  event,
  userIsParticipant,
  onJoin,
  onLeave,
  onShare,
}) => (
  <div className="flex flex-col md:flex-row gap-2 w-full">
    <button
      onClick={userIsParticipant ? onLeave : onJoin}
      className={`py-2 text-white font-bold rounded-lg md:flex-grow ${
        userIsParticipant
          ? "bg-red-500 hover:bg-red-600"
          : "bg-gray-900 hover:bg-gray-800"
      }`}
    >
      {userIsParticipant ? "Salir del evento" : "Unirse al evento"}
    </button>
    <button
      onClick={onShare}
      className="py-2 border border-gray-900 text-gray-900 font-bold rounded-lg hover:bg-gray-100 md:flex-grow"
    >
      Compartir
    </button>
  </div>
);

const RegisterDialog = ({ open, onClose, onLogin, onSignup }) => (
  <Dialog
    open={open}
    onClose={onClose}
    fullWidth
    maxWidth="xs"
    className="rounded-lg shadow-lg"
  >
    <DialogTitle className="text-center font-bold text-xl text-gray-900">
      Bienvenido
    </DialogTitle>
    <DialogContent className="px-8 py-6">
      <p className="text-gray-700 text-center text-sm">
        Regístrate o inicia sesión para unirte o pagar por el evento.
      </p>
    </DialogContent>
    <DialogActions className="flex flex-col gap-4 pb-8 px-8">
      <Button
        onClick={onLogin}
        className="w-full py-3 bg-black text-white font-semibold rounded-lg hover:bg-gray-800 transition-all duration-200"
      >
        Iniciar sesión
      </Button>
      <Button
        onClick={onSignup}
        className="w-full py-3 bg-white text-black font-semibold border border-gray-300 rounded-lg hover:bg-gray-100 transition-all duration-200 shadow-sm"
      >
        Crear cuenta
      </Button>
    </DialogActions>
  </Dialog>
);

export default EventDetail;
