import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  TextField,
  Autocomplete,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Switch,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../../../firebase";
import {
  doc,
  getDoc,
  updateDoc,
  addDoc,
  collection,
  getDocs,
  serverTimestamp,
  deleteDoc,
  query,
  where,
} from "firebase/firestore";
import { eventsApi } from "../../../lib/client";

export const TagStatus = Object.freeze({
  available: 0,
  sold: 1,
  in_rent: 2,
});

export const TagEvents = Object.freeze({
  0: "Venta",
  1: "Renta",
  2: "Devolución",
});

function TagActions() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [tag, setTag] = useState(null);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedButton, setSelectedButton] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingUsers, setIsLoadingUsers] = useState(true);
  const [isForEvent, setIsForEvent] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState("");
  const [allEvents, setAllEvents] = useState(null);
  const [runs, setRuns] = useState([]);

  useEffect(() => {
    const fetchTagAndRuns = async () => {
      const tagRef = doc(db, "Tags", id);
      const tagSnap = await getDoc(tagRef);
      if (tagSnap.exists()) {
        const tagData = tagSnap.data();

        if (tagData.Owner) {
          const userRef = doc(db, "Users", tagData.Owner);

          const userSnapshot = await getDoc(userRef);
          if (userSnapshot.exists()) {
            tagData.OwnerName = userSnapshot.data().name;
          }
        }

        const runsQuery = query(
          collection(db, "Runs"),
          where("NFCIdentifier", "==", tagData.NFCID)
        );
        const runsSnapshot = await getDocs(runsQuery);

        const runsData = await Promise.all(
          runsSnapshot.docs.map(async (runDoc) => {
            const runData = runDoc.data();

            let eventName = "Sin evento";

            if (runData.EventID) {
              const eventRef = doc(db, "events", runData.EventID);
              const eventSnap = await getDoc(eventRef);

              eventName = eventSnap.exists()
                ? eventSnap.data().eventName
                : "Evento desconocido";
            }

            return {
              id: runDoc.id,
              ...runData,
              eventName,
            };
          })
        );

        setTag(tagData);
        setRuns(runsData);
        setIsLoading(false);
      } else {
        console.error("Tag no encontrado");
      }
    };

    fetchTagAndRuns();
  }, [id]);

  const fetchUsers = async () => {
    setIsLoadingUsers(true);
    const userCollection = collection(db, "Users");
    const userSnapshot = await getDocs(userCollection);
    const userData = userSnapshot.docs.map((userDoc) => ({
      id: userDoc.id,
      email: userDoc.data().email,
      name: userDoc.data().name,
    }));
    setUsers(userData);
    setIsLoadingUsers(false);
  };

  const handleSelectEvent = async (e) => {
    setIsLoadingUsers(true);
    const event = e.target.value;

    var eventData = await eventsApi.getEventById(event, {
      participants: true,
    });

    const eventParticipants = eventData.participants.map((ep) => ({
      id: ep.user.id,
      email: ep.user.email,
      name: ep.user.name,
    }));
    setUsers(eventParticipants);
    setSelectedEvent(event);
    setIsLoadingUsers(false);
  };

  const fetchAllEvents = async () => {
    const allEventsData = await eventsApi.getAllEvents(true);
    setAllEvents(allEventsData);
  };

  const handleCheckIsForEvent = (e) => {
    setSelectedUser(null);
    if (e.target.checked) {
      fetchAllEvents();
      setUsers([]);
      setIsForEvent(e.target.checked);
    } else {
      setSelectedEvent("");
      fetchUsers();
      setIsForEvent(e.target.checked);
    }
  };

  const updateTagStatus = async (status, event) => {
    if (!tag || (status !== TagStatus.available && !selectedUser)) return;

    setIsSubmitting(true);
    const tagRef = doc(db, "Tags", id);
    const updateData = { status };

    if (status === TagStatus.available) {
      updateData.Owner = "";
    } else {
      updateData.Owner = selectedUser.id;
    }

    await updateDoc(tagRef, updateData);

    const logRef = collection(db, "TagLog");
    await addDoc(logRef, {
      tagId: id,
      event,
      userId: "currentUserId", // Cambiar con el ID del usuario actual
      createdAt: serverTimestamp(),
    });

    setTag((prev) => ({ ...prev, ...updateData }));
    setIsSubmitting(false);
    setSelectedUser(null);
    setSelectedButton("");
  };

  const handleDeleteTag = async () => {
    if (window.confirm("¿Estás seguro de que quieres eliminar esta pulsera?")) {
      try {
        const tagRef = doc(db, "Tags", id);
        await deleteDoc(tagRef);
        navigate(-1);
      } catch (error) {
        console.error("Error eliminando el tag:", error);
      }
    }
  };

  const handleTagReturn = async () => {
    if (window.confirm("¿Estás seguro de que quieres devolver esta pulsera?")) {
      updateTagStatus(TagStatus.available, "Devolución");
      navigate(-1);
      // try {
      //   const tagRef = doc(db, "Tags", id);
      //   await deleteDoc(tagRef);
      // navigate(-1);
      // } catch (error) {
      //   console.error("Error eliminando el tag:", error);
      // }
    }
  };

  if (isLoading)
    return <Typography>Cargando información de la pulsera...</Typography>;

  if (!tag) return <Typography>No hay tag</Typography>;

  return (
    <Box p={3}>
      <Button variant="contained" onClick={() => navigate(-1)}>
        Atrás
      </Button>
      <Box mt={2}>
        <Typography variant="h6">Detalles de la Pulsera</Typography>
        <Typography>
          <strong>ID:</strong> {id}
        </Typography>
        <Typography>
          <strong>NFCID:</strong> {tag.NFCID}
        </Typography>
        <Typography>
          <strong>Id de propietario:</strong> {tag.Owner || "Sin propietario"}
        </Typography>
        <Typography>
          <strong>Nombre de propietario:</strong>{" "}
          {tag.OwnerName || "Sin propietario"}
        </Typography>
        <Typography>
          <strong>Tipo:</strong> {tag.TagType}
        </Typography>
        <Typography>
          <strong>Estado:</strong> {tag.status}
        </Typography>

        <Box mt={2} display="flex" gap={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setSelectedButton("venta");
              fetchUsers();
            }}
            disabled={tag.status === TagStatus.sold}
          >
            Vender
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setSelectedButton("renta");
              fetchUsers();
            }}
            disabled={tag.status === TagStatus.in_rent}
          >
            Rentar
          </Button>
          <Button
            variant="contained"
            onClick={handleTagReturn}
            disabled={tag.status === TagStatus.available}
          >
            Devolver
          </Button>
          <Button variant="contained" color="error" onClick={handleDeleteTag}>
            Eliminar
          </Button>
        </Box>
        <Divider sx={{ my: 2 }} />
        {selectedButton === "venta" || selectedButton === "renta" ? (
          <div className="bg-slate-100 rounded-xl shadow p-4">
            <Typography variant="h4">
              {selectedButton === "venta"
                ? "Asignar Venta"
                : selectedButton === "renta"
                ? "Asignar Renta"
                : null}
            </Typography>
            <Box mt={2}>
              <div className="bg-slate-200 border rounded p-2">
                <FormControlLabel
                  control={
                    <Switch
                      checked={isForEvent}
                      onChange={handleCheckIsForEvent}
                    />
                  }
                  label="Asignar para evento"
                />
                {isForEvent && (
                  <FormControl fullWidth disabled={!isForEvent}>
                    <InputLabel id="demo-simple-select-label">
                      Selecciona el evento
                    </InputLabel>
                    <Select
                      value={selectedEvent}
                      label="Age"
                      onChange={handleSelectEvent}
                    >
                      {allEvents &&
                        allEvents.map((e) => (
                          <MenuItem value={e.id}>{e.eventName}</MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                )}
              </div>

              <Typography variant="subtitle1">
                Seleccionar Propietario
              </Typography>

              {isLoadingUsers ? (
                <CircularProgress />
              ) : (
                <Autocomplete
                  value={selectedUser}
                  onChange={(e, newValue) => setSelectedUser(newValue)}
                  options={users}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Buscar Usuario"
                      variant="outlined"
                    />
                  )}
                  getOptionLabel={(user) =>
                    `${user.name} - ${user.id.substring(0, 5)}`
                  }
                />
              )}

              {selectedUser && (
                <Button
                  variant="contained"
                  color="success"
                  onClick={() =>
                    updateTagStatus(
                      selectedButton === "venta"
                        ? TagStatus.sold
                        : TagStatus.in_rent,
                      selectedButton === "venta" ? "Venta" : "Renta"
                    )
                  }
                  disabled={isSubmitting}
                  sx={{ mt: 2 }}
                >
                  {isSubmitting ? <CircularProgress size={24} /> : "Confirmar"}
                </Button>
              )}
            </Box>
          </div>
        ) : null}

        {/* Tabla de Runs */}
        <Box mt={4}>
          <Typography variant="h6">Historial de Runs</Typography>
          {runs.length > 0 ? (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Event</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {runs.map((run) => (
                    <TableRow key={run.id}>
                      <TableCell>{run.id}</TableCell>
                      <TableCell>{run.eventName}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography>No se encontraron registros de runs.</Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default TagActions;
