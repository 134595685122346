import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  GoogleAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail,
} from "firebase/auth";
import { auth } from "../firebase";
import GoogleIcon from "../assets/google-icon.png";
import { useUserAuth } from "../context/userAuthContext";

const Login = () => {
  const { logIn } = useUserAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [showResetForm, setShowResetForm] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      // Lógica para iniciar sesión
      await logIn(email, password);
      navigate("/home");
    } catch (err) {
      setError(err.message);
    }
  };

  const handleGoogleSignIn = async (e) => {
    e.preventDefault();
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      navigate("/home");
    } catch (error) {
      setError(error.message);
    }
  };

  const handleResetPassword = async () => {
    setError("");
    try {
      if (!email) {
        setError("Por favor ingresa tu correo electrónico.");
        return;
      }
      await sendPasswordResetEmail(auth, email);
      alert(
        "Se ha enviado un correo electrónico para restablecer tu contraseña."
      );
      setShowResetForm(false);
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-50 px-4">
      <div className="max-w-md w-full bg-white rounded-xl shadow-lg p-6">
        <div className="text-center mb-6">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/LiveSpeed.png?alt=media&token=f30dbc3b-6932-486d-b992-3a705f04f0b5"
            alt="Livespeed Logo"
            className="w-36 mx-auto mb-4"
          />
          <h1 className="text-2xl font-bold text-gray-800">
            Bienvenido a Livespeed
          </h1>
        </div>

        {error && (
          <div className="mb-4 p-3 bg-red-100 text-red-800 rounded-lg">
            {error}
          </div>
        )}

        {!showResetForm ? (
          <>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Correo Electrónico
                </label>
                <input
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-black focus:outline-none"
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Contraseña
                </label>
                <input
                  id="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-black focus:outline-none"
                  required
                />
              </div>
              <button
                type="submit"
                className="w-full py-3 bg-black text-white text-lg rounded-lg shadow-md hover:bg-gray-800 transition"
              >
                Iniciar Sesión
              </button>
            </form>
            <div className="mt-4 text-center">
              <button
                onClick={() => setShowResetForm(true)}
                className="text-sm text-gray-600 underline hover:text-black transition"
              >
                ¿Olvidaste tu contraseña?
              </button>
            </div>
          </>
        ) : (
          <div>
            <h2 className="text-lg font-semibold text-gray-800 mb-4 text-center">
              Restablecer Contraseña
            </h2>
            <p className="text-sm text-gray-600 mb-4 text-center">
              Ingresa tu correo electrónico para enviar un enlace de
              restablecimiento.
            </p>
            <div className="space-y-4">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Correo Electrónico"
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-black focus:outline-none"
                required
              />
              <button
                onClick={handleResetPassword}
                className="w-full py-3 bg-black text-white text-lg rounded-lg shadow-md hover:bg-gray-800 transition"
              >
                Enviar Enlace
              </button>
            </div>
            <div className="mt-4 text-center">
              <button
                onClick={() => setShowResetForm(false)}
                className="text-sm text-gray-600 underline hover:text-black transition"
              >
                Regresar al inicio de sesión
              </button>
            </div>
          </div>
        )}

        <div className="mt-4">
          <button
            onClick={handleGoogleSignIn}
            className="w-full flex items-center justify-center py-3 border border-gray-300 bg-white text-gray-700 rounded-lg shadow-md hover:bg-gray-100 transition"
          >
            <img src={GoogleIcon} alt="Google" className="w-5 h-5 mr-3" />
            Iniciar Sesión con Google
          </button>
        </div>

        <p className="text-center mt-4 text-sm text-gray-600">
          ¿No tienes una cuenta?{" "}
          <Link
            to="/signup"
            className="text-black underline hover:text-gray-800 transition"
          >
            ¡Crea una!
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Login;
